import React from "react";
import { Box } from "@mui/material";
//webp
import baslicaEntegrasyon from "../../../../utils/assets/baslicaEntegrasyon.webp";
import baslicaEntegrasyonS from "../../../../utils/assets/baslicaEntegrasyonS.webp";
import { useScreenBreakPoints } from "../../../../theme";
function Entegration() {
  const { smScreen, mdScreen, lgScreen, xlScreen } = useScreenBreakPoints();
  return (
    <Box
      sx={{
        width: "100%",
        height: !smScreen ? "60vh" : "100vh",
        backgroundColor: "#030213",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <img
        alt="baslica entegrasyon"
        src={!smScreen ? baslicaEntegrasyonS : baslicaEntegrasyon}
        width={"80%"}
        height={!smScreen ? 25 : 100}
        style={{
          // marginTop: xlScreen ? 500 : lgScreen ? 200 : smScreen ? 0 : 100,
          marginTop: 80,
        }}
      />
      <img
        alt="baslica entegrasyon"
        src={"https://static.sepettakip.com/st-web-assets/bgEnteg1.webp"}
        width={"95%"}
        height={!smScreen ? 25 : 100}
      />
    </Box>
  );
}

export default Entegration;
