import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../components/error";
import registrationService from "../services/registrationService";
import axios from "axios";
const { sha256 } = require("js-sha256");

export const RegistrationContext = createContext();

const RegistrationProvider = (props) => {
  let history = useHistory();
  const { alert, success } = useToast();

  const [sectorList, setSectorList] = useState(null);
  const [cityy, setCityy] = useState(null);
  const [townn, setTownn] = useState(null);
  const [neighh, setNeighh] = useState(null);

  const getCityFunc = () => {
    registrationService
      .get_city()
      .then((response) => {
        setCityy(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getTownFunc = (city_id) => {
    registrationService
      .get_town(city_id)
      .then((response) => {
        setTownn(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getNeighFunc = (city_id, town_id) => {
    registrationService
      .get_neigh(city_id, town_id)
      .then((response) => {
        setNeighh(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getSectorListFunc = () => {
    registrationService
      .get_application_sector()
      .then((response) => {
        setSectorList(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const registrationFunc = (data) => {
    registrationService
      .registration(data)
      .then((response) => {
        history.push("/completed-registration");
        axios.post(
          "https://graph.facebook.com/v17.0/1367449240725781/events?access_token=EAASV1mLmKuMBO8xUEWQE5Y9FtvNmIthN0cwVFgaOuGHmpiBSqrEr3J0NBsd1cS8z2wZBOccf7EYhY68TZBFe7G4zF9g8cb6Psej8xsLJ2vQtlDbXNPbCP1EvAk7Fowzqz305uciEkqqg9aKkLIAuwNNnhE1lY32ThDA2DLuXmmBTN7fF0yhk9Fz1Sv1ngZAEc6ZCnkLjMCabNo8iRw64t5O0VkXxZBF4zgcZCMI5klnluXdcOgHGYd",
          {
            data: [
              {
                event_name: "St-basvuru",
                action_source: "website",
                event_source_url: "https://sepettakip.com/",
                event_time: Math.floor(new Date().getTime() / 1000),
                user_data: {
                  em: sha256(data?.email),
                  ph: sha256(data?.phone),
                  fn: sha256(data?.full_name),
                },
                custom_data: {
                  rs_name: data?.restaurant_name,
                },
              },
            ],
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const contactUsFunc = (data) => {
    registrationService
      .contact_us(data)
      .then((response) => {
        success(
          "Talebiniz için teşekkür ederiz. En kısa sürede sizinle iletişime geçilecektir."
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <RegistrationContext.Provider
      value={{
        getSectorListFunc,
        sectorList,
        registrationFunc,
        contactUsFunc,
        getCityFunc,
        cityy,
        getTownFunc,
        townn,
        getNeighFunc,
        neighh,
      }}
    >
      {props.children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
