import React from "react";
import { Box, useMediaQuery, createTheme } from "@mui/material";

import aboutImage from "../../utils/assets/hakkimizda.webp";

const AboutUs = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        height: matches ? "100vh" : "auto",
      }}
      style={{
        background: "linear-gradient(119deg, #617e9d 1%, #809abd 100%)",
      }}
    >
      <Box
        sx={{
          background: `url(${aboutImage})`,
          backgroundSize: "cover",
          height: matches ? "100%" : "375px",
          backgroundPosition: "center",
          display: "flex",
          alignItems: matches ? "center" : "",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: matches ? "absolute" : "static",
          bottom: "0px",
          left: "0px",
          background: "white",
          maxWidth: "670px",
          height: matches ? "65%" : "auto",
          paddingBottom: matches ? "0px" : "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: matches ? "85px" : "20px",
            paddingBottom: matches ? "45px" : "20px",
            paddingRight: matches ? "48px" : "20px",
            paddingLeft: matches ? "125px" : "20px",
          }}
        >
          <span
            style={{
              fontFamily: "ubuntuB",
              fontSize: matches ? 30 : 20,
              color: "#293c51",
              marginBottom: matches ? "20px" : "15px",
            }}
          >
            Hakkımızda
          </span>
          <span
            style={{
              fontSize: 15,
              color: "#293c51",
              lineHeight: matches ? "34px" : "26px",
              fontFamily: "ubuntuL",
            }}
          >
            Çoğalan yemek siparişleri ve platformları nedeniyle restoranlar,
            sipariş memnuniyeti, ürün maliyetleri gibi alanların kontrollerinde
            zorlanmaya başlamıştır. Kullanılan mevcut pos sistemlerinin de
            sektörde yetersiz olması, SepetTakip sisteminin yenilikçi
            özelliklerine ihtiyaç duyulduğunu ve önemini göstermektedir.
            SepetTakip Yaz. Tek. A.Ş. olarak 2022 yılında faaliyete başlayan
            SepetTakip giderek büyüyen ve yeni yatırımlara zemin oluşturan bu
            sektörün temel taşı olan restoranların ihtiyaçlarını karşılayacak
            yüksek teknolojili bir yatırımdır. Basit ve kullanımı rahat arayüzü
            ile restoranların online sipariş almada ihtiyacı olan tüm
            özellikleri teknolojik bir ekosistem içinde sunmaktadır.
          </span>
        </div>
      </div>
    </Box>
  );
};

export default AboutUs;
