import React from "react";
import { Box, useMediaQuery, createTheme, Typography } from "@mui/material";

import contactImage from "../../utils/assets/map.webp";

const Contact = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        height: matches ? "100vh" : "auto",
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 100px)",
          display: "flex",
          flexDirection: matches ? "row" : "column",
          alignItems: "center",
          paddingTop: "100px",
        }}
      >
        <Box
          sx={{
            width: matches ? "50%" : "100%",
            fontSize: "20px",
            textAlign: matches ? "left" : "center",
            marginBottom: matches ? "0px" : "50px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: "30px",
              fontSize: "34px",
              fontWeight: 600,
              color: "#293c51",
              fontFamily: "ubuntuB",
            }}
          >
            İletişim
          </Typography>
          <p
            style={{
              margin: "0px",
              fontSİze: "20px",
              color: "#293c51",
              lineHeight: 1.7,
              fontFamily: "ubuntuM",
            }}
          >
            Kazlıçeşme Mah. 245. Sok No: 5 <br />
            PK. 34010 Zeytinburnu, İstanbul/Türkiye
            <br />
            <a
              href="tel:08502552004"
              style={{
                display: "block",
                color: "#293c51",
                textDecoration: "none",
                fontWeight: 300,
                fontFamily: "ubuntuL",
              }}
            >
              0850 255 20 04
            </a>
            <a
              href={`mailto:info@sepettakip.com`}
              style={{
                color: "#293c51",
                textDecoration: "none",
                fontWeight: 300,
                fontFamily: "ubuntuL",
              }}
            >
              info@sepettakip.com
            </a>
          </p>
          <p
            style={{
              margin: "0px",
              fontSİze: "20px",
              fontWeight: 600,
              color: "#293c51",
            }}
          ></p>
        </Box>
        <Box
          component="img"
          sx={{
            height: "auto",
            width: matches ? "50%" : "100%",
          }}
          alt="The house from the offer."
          src={contactImage}
        />
      </Box>
    </Box>
  );
};

export default Contact;
