import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@mui/styles";
import { BrowserRouter } from "react-router-dom";

const useStyles = makeStyles(() => ({
  variantInfo: {
    backgroundColor: "#030213",
  },
}));

export const NotistackProvider = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={classes}
      children={children}
      maxSnack={4}
      dense
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NotistackProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </NotistackProvider>
);
