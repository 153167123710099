import React from "react";
import Modal from "@mui/material/Modal";

import { Card } from "@mui/material";

import contractPdf from "../../utils/stKvkk.pdf";

const KvkkModal = ({ kvkkModal, setKvkkModal }) => {
  return (
    <Modal
      onClose={() => {
        setKvkkModal(false);
      }}
      open={kvkkModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          boxShadow: "none",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "95%",
          }}
        >
          <iframe src={contractPdf} title={"q"} width="98%" height={"100%"} />
        </div>
      </Card>
    </Modal>
  );
};

export default KvkkModal;
