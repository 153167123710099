import Box from "@mui/material/Box";
import { useScreenBreakPoints } from "../../theme";

import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import img1 from "../../utils/assets/stoktakip/img-1.webp";
import img2 from "../../utils/assets/stoktakip/img-2.webp";
import img3 from "../../utils/assets/stoktakip/img-3.webp";
import img4 from "../../utils/assets/stoktakip/img-4.webp";
import img5 from "../../utils/assets/stoktakip/img-5.webp";
import img6 from "../../utils/assets/stoktakip/img-6.webp";
import bgEllipse from "../../utils/assets/bg-ellipse.webp";
import bg from "../../utils/assets/why-sepettakip/bg.webp";
import sepetBg from "../../utils/assets/sepetapp-bg.webp";
import ellipse from "../../utils/assets/ellipse.webp";

const result = [
  {
    ay: "Tiramisu",
    fiyat: "55,70₺",
    maliyet: "19,20₺",
    satis: "10",
    ciro: "557,70₺",
    brut: "365,00₺",
    mal: "%34,4",
    kar: "%290",
    beklenen: "%300",
    percent: "-10%",
    restoran: "1,9₺",
    app: "2,3₺",
  },
  {
    ay: "Lahmacun",
    fiyat: "19,70₺",
    maliyet: "25,20₺",
    satis: "30",
    ciro: "437,70₺",
    brut: "255,00₺",
    mal: "%27,4",
    kar: "%540",
    beklenen: "%400",
    percent: "20%",
    restoran: "3,4₺",
    app: "1,7₺",
  },
  {
    ay: "Pilav Üstü",
    fiyat: "44,70₺",
    maliyet: "23,15₺",
    satis: "23",
    ciro: "243,70₺",
    brut: "111,00₺",
    mal: "%34,4",
    kar: "%80",
    beklenen: "%120",
    percent: "15%",
    restoran: "5,2₺",
    app: "6,7₺",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableRow-root.MuiTableRow-head": {
      backgroundColor: "#f53f62",

      "& .MuiTableCell-root": {
        color: "#fff",
        fontSize: 20,
        fontFamily: "ubuntuL",
        fontWeight: 600,
      },
    },

    "& .MuiTableRow-root": {
      backgroundColor: "#293c51",

      "& .MuiTableCell-root": {
        fontSize: 20,
        fontFamily: "ubuntuL",
        fontWeight: 600,
        color: "#fff",
      },
    },
  },
}));

export default function StokTakip() {
  const { xxlScreen, xlScreen, lgScreen, mdScreen, smScreen } =
    useScreenBreakPoints();

  const classes = useStyles();
  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f5",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={bgEllipse}
        alt="ellipse"
        style={{ position: "absolute", left: "-40%", opacity: 0.7, zIndex: 1 }}
      />

      <h1
        style={{
          fontSize: xxlScreen
            ? "60px"
            : xlScreen
            ? "50px"
            : lgScreen
            ? "40px"
            : "24px",
          fontFamily: "ubuntuB",
          fontWeight: "bold",
          color: "#24233a",
          marginBottom: 10,
          textAlign: "center",
          maxWidth: 1100,
          margin: "70px auto 40px",
          padding: mdScreen ? 0 : "0px 20px",
        }}
      >
        Stok Takibi Hiç Bu Kadar Detaylı ve Kolay Olmamıştı…
      </h1>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          zIndex: 5,
        }}
      >
        <img src={img1} style={{ height: "auto", maxWidth: "100%" }} />
      </Box>

      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          width: mdScreen ? "80%" : "90%",
          margin: "auto",
          marginBottom: mdScreen ? 10 : 2,
        }}
      >
        <TableContainer className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Son 1 Ay</TableCell>
                <TableCell align="center">Ort. Fiyat</TableCell>
                <TableCell align="center">Maliyet</TableCell>
                <TableCell align="center">Satış</TableCell>
                <TableCell align="center">Ciro</TableCell>
                <TableCell align="center">Brüt Kâr</TableCell>
                <TableCell align="center">Maliyet</TableCell>
                <TableCell align="center">Kâr</TableCell>
                <TableCell align="center">Beklenen Kâr</TableCell>
                <TableCell align="center">+/- Kâr</TableCell>
                <TableCell align="center">Restoran Zam</TableCell>
                <TableCell align="center">Özel App Zam</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">{row.ay}</TableCell>
                    <TableCell align="center">{row.fiyat}</TableCell>
                    <TableCell align="center">{row.maliyet}</TableCell>
                    <TableCell align="center">{row.satis}</TableCell>
                    <TableCell align="center">{row.ciro}</TableCell>
                    <TableCell align="center">{row.brut}</TableCell>
                    <TableCell align="center">{row.mal}</TableCell>
                    <TableCell align="center">{row.kar}</TableCell>
                    <TableCell align="center">{row.beklenen}</TableCell>
                    <TableCell align="center">{row.percent}</TableCell>
                    <TableCell align="center">{row.restoran}</TableCell>
                    <TableCell align="center">{row.app}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: mdScreen ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={img2}
          alt="image-2"
          style={{ height: "auto", maxWidth: "100%" }}
        />
        <p
          style={{
            fontSize: xxlScreen ? "30px" : xlScreen ? "24px" : "18px",
            fontFamily: "ubuntuL",
            fontWeight: 300,
            color: "#24233a",
            maxWidth: 450,
            textAlign: "center",
            margin: mdScreen ? "0px 50px" : "20px 50px",
          }}
        >
          Önerilen zam oranlarını dijital menünüzde ve Özel APP’inizde tek tuşla
          güncelleyin.
        </p>
        <img
          src={img3}
          alt="image-3"
          style={{ height: "auto", maxWidth: "100%" }}
        />
      </Box>

      <Box
        sx={{
          width: mdScreen ? "80%" : "90%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: mdScreen ? 10 : 2,
        }}
      >
        <Box>
          <img
            src={img4}
            alt="image-4"
            style={{ height: "auto", maxWidth: "100%" }}
          />
        </Box>

        <Box>
          <p
            style={{
              fontSize: xxlScreen ? "30px" : xlScreen ? "24px" : "18px",
              fontFamily: "ubuntuL",
              fontWeight: 300,
              color: "#24233a",
              maxWidth: 600,
            }}
          >
            Satın alımlarınızı envanterinize kolayca girin. Reçete oluşturarak
            ürün maliyetlerinizi yakından takip edin.
          </p>
        </Box>
      </Box>

      <Box sx={{ position: "relative", marginTop: 5 }}>
        <img
          src={bg}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
          alt="img-bg"
        />

        <img
          alt="sepet-bg"
          src={sepetBg}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 2,
            height: "auto",
            maxWidth: "100%",
          }}
        />

        <img
          src={ellipse}
          alt="ellipse"
          style={{
            position: "absolute",
            right: -200,
            top: 0,
            transform: "translate(50%, 0%)",
            zIndex: 1,
            display: mdScreen ? "block" : "none",
          }}
        />

        <Box sx={{ position: "relative", zIndex: 7 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column-reverse",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box sx={{ maxWidth: "600px", marginLeft: mdScreen ? 10 : 0 }}>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                  textAlign: mdScreen ? "left" : "center",
                }}
              >
                Kritik uyarı sistemi sayesinde stoğu tükenmeye yakın olan ve son
                tüketim tarihi (STT) yaklaşan ürünlerinizi tespit edin.
              </p>
            </Box>

            <Box>
              <img
                src={img5}
                alt="image-5"
                style={{ height: "auto", maxWidth: "100%" }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ position: "relative", marginBottom: 12, zIndex: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box>
              <img
                src={img6}
                alt="img-6"
                style={{ height: "auto", maxWidth: "100%" }}
              />
            </Box>

            <Box sx={{ maxWidth: "600px", marginRight: mdScreen ? 10 : 0 }}>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                  textAlign: mdScreen ? "right" : "center",
                }}
              >
                Platformlardaki ürünlerinizi eşleştirin. Komisyon oranlarını ve
                ürün fiyatlarınızı kolayca girin.
                <br />
                <br /> Restoran ve Özel APP’inize ek olarak çalıştığınız
                platformları da raporlayalım.
              </p>
            </Box>
          </Box>

          <h1
            style={{
              fontSize: xxlScreen
                ? "60px"
                : xlScreen
                ? "50px"
                : lgScreen
                ? "40px"
                : "24px",
              fontFamily: "ubuntuB",
              fontWeight: "bold",
              color: "#24233a",
              marginBottom: "32px",
              fontStyle: "italic",
              textAlign: "right",
              marginTop: 20,
              position: "relative",
              zIndex: 5,
              maxWidth: 750,
              marginLeft: "auto",
              marginRight: mdScreen ? 200 : 10,
            }}
          >
            SİSTEM HESAPLASIN, SİZ İŞİNİZE ODAKLANIN…
          </h1>
        </Box>
      </Box>
    </Box>
  );
}
