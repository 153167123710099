import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, styled, Button, Drawer, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo2 from "../../utils/assets/sepetTakipW2.webp";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  background: "#030213",
});

const CustomButton = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  color: "#f87a93",
  padding: "5px 10px",
  fontWeight: 600,
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: "#fbabc7",
    color: "#fff",
  },
}));

export const Navbar = () => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let path = history.location.pathname;

    setActiveLink(path);
  }, [history.location]);

  return (
    <AppBar position="static">
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: 160,
            }}
          >
            <img
              onClick={() => {
                history.push("/");
                setActiveLink("/");
              }}
              alt="logo-2"
              src={logo2}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          {activeLink !== "/registration" ? (
            <Button
              onClick={() => {
                history.push("/registration");
                setActiveLink("/registration");
              }}
              style={{
                backgroundColor: "black",
                textTransform: "capitalize",
                borderRadius: 13,
                height: 28,
                borderColor: "white",
                border: "solid",
                fontSize: 16,
                minWidth: "120px",
              }}
              size="small"
              variant="contained"
            >
              Sizi Arayalım
            </Button>
          ) : (
            <div style={{ minWidth: 165 }}></div>
          )}
          <Box sx={{ marginRight: 1, height: 26 }}>
            <MenuIcon
              sx={{ color: "#fff", fontSize: "24px" }}
              onClick={toggleDrawer}
            />
          </Box>
          <Drawer anchor="left" open={open} onClose={toggleDrawer}>
            <Box
              sx={{
                width: "200px",
                paddingTop: 5,
                paddingLeft: 1,
              }}
            >
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/about-us");
                  setActiveLink("/about-us");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Hakkımızda</span>
                {activeLink == "/about-us" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/offer");
                  setActiveLink("/offer");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Fiyatlarımız</span>
                {activeLink == "/offer" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/references");
                  setActiveLink("/references");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Referanslarımız</span>
                {activeLink == "/references" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/contact");
                  setActiveLink("/contact");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>İletişim</span>
                {activeLink == "/contact" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <h4 style={{ marginBottom: 10 }}>Ürünler</h4>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/why-sepettakip");
                  setActiveLink("/why-sepettakip");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Sepet Takip</span>
                {activeLink == "/why-sepettakip" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/why-sepetapp");
                  setActiveLink("/why-sepetapp");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>SepetApp</span>
                {activeLink == "/why-sepetapp" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/callerid");
                  setActiveLink("/callerid");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Caller ID</span>
                {activeLink == "/callerid" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/stoktakip");
                  setActiveLink("/stoktakip");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>StokTakip</span>
                {activeLink == "/stoktakip" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/kuryeapp");
                  setActiveLink("/kuryeapp");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>KuryeTakip</span>
                {activeLink == "/kuryeapp" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/sepetfast");
                  setActiveLink("/sepetfast");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>SepetFast</span>
                {activeLink == "/sepetfast" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                  history.push("/why-masatakip");
                  setActiveLink("/why-masatakip");
                }}
                style={{
                  marginBottom: 8,
                  paddingBottom: 8,
                  borderBottom: "1px solid #f4f4f4",
                  fontWeight: 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>MasaTakip</span>
                {activeLink == "/why-masatakip" ? (
                  <div
                    style={{
                      width: 20,
                      height: 2,
                      backgroundColor: "white",
                      marginTop: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};
