import { Button, Divider, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import offerImage from "../../../utils/assets/offer.webp";
import { useEffect } from "react";
import {
  ArrowRightAltOutlined,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import moment from "moment/moment";

const MakeAnOffer = ({ matches, code, offerDetail }) => {
  let history = useHistory();
  const matches1650 = useMediaQuery("(min-width:1650px)");
  const matches1450 = useMediaQuery("(min-width:1450px)");
  const matches1180 = useMediaQuery("(min-width:1180px)");
  const [offerDetailObj, setOfferDetailObj] = useState(null);

  useEffect(() => {
    if (offerDetail !== null) {
      const newOfferObj = offerDetail?.product_offers_new?.concat(
        offerDetail?.module_offers_new
      );
      setOfferDetailObj(newOfferObj);
    }
  }, [offerDetail]);

  return (
    <div
      style={{
        marginTop: matches ? 30 : 0,
        height: "95%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          height: "90%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: matches && matches1180 ? "50%" : "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <span
              style={{
                fontSize: matches ? 50 : 32,
                fontFamily: "ubuntuL",
                color: "#293c51",
              }}
            >
              Güncel Teklifiniz
            </span>
            <div
              style={{
                fontSize: matches ? 20 : 18,
                fontFamily: "ubuntuL",
                color: "#293c51",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              Kullanım başlangıç:{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontFamily: "ubuntuL",
                    fontSize: matches ? 20 : 18,
                  }}
                >
                  {moment(offerDetail?.starts_at).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
            {/* <div
              style={{
                fontSize: matches ? 20 : 18,
                fontFamily: "ubuntuL",
                color: "#293c51",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              Kullanım bitiş:{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontFamily: "ubuntuL",
                    fontSize: matches ? 20 : 18,
                  }}
                >
                  {moment(offerDetail?.expires_at).format("DD-MM-YYYY")}
                </span>
              </div>
            </div> */}
            <Divider />
            {offerDetailObj?.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      width: "100%",
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: matches ? 20 : 18,
                        fontFamily: "ubuntuB",
                        color: "#293c51",
                      }}
                    >
                      {item?.product?.name || item?.module?.name} ({item?.count}
                      x)
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {item?.base_amount !== item?.amount && (
                        <>
                          <span
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                              fontFamily: "ubuntuB",
                              fontSize: matches ? 20 : 18,
                              color: "#293c51",
                            }}
                          >
                            {item?.base_amount}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ArrowRightAltOutlined />
                          </div>
                        </>
                      )}

                      <span
                        style={{
                          fontFamily: "ubuntuB",
                          fontSize: matches ? 20 : 18,
                          color: "#293c51",
                        }}
                      >
                        {item?.amount}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div>
            <div
              style={{
                fontSize: matches ? 22 : 18,
                fontFamily: "ubuntuL",
                color: "#293c51",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#293c51",
                marginTop: 15,
              }}
            >
              Toplam İndirim
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontFamily: "ubuntuB",
                    color: "#293c51",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  - {offerDetail?.total_base_amount - offerDetail?.total_amount}{" "}
                </span>
              </div>
            </div>
            <div
              style={{
                fontSize: matches ? 22 : 18,
                fontFamily: "ubuntuL",
                color: "#293c51",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "red",
                marginTop: 15,
              }}
            >
              Toplam fiyat(KDV Dahil):
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    textDecoration: "line-through",
                    textDecorationColor: "black",
                    fontFamily: "ubuntuB",
                  }}
                >
                  {offerDetail?.total_base_amount}
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ArrowRightAltOutlined />
                </div>
                <span
                  style={{
                    fontFamily: "ubuntuB",
                    textDecoration: "underline",
                  }}
                >
                  {offerDetail?.total_amount}
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                if (history.location.pathname.startsWith("/offers_v2")) {
                  history.push(`/offers_v2/1/${code}/1`);
                } else {
                  history.push(`/offers/1/${code}/1`);
                }
              }}
              style={{
                backgroundColor: "#293c51",
                textTransform: "capitalize",
                borderRadius: 13,
                fontFamily: "ubuntuL",
                width: 180,
                height: 36,
                borderColor: "gray",
                border: "solid",
                fontSize: 18,
              }}
              size="small"
              variant="contained"
            >
              Ödemeye Geç
            </Button>
          </div>
        </div>
        {matches && matches1180 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={offerImage}
              alt="offerImage"
              style={{
                width: matches1650
                  ? 900
                  : matches1450
                  ? 800
                  : matches1180
                  ? 600
                  : 500,
                height: matches1650
                  ? 600
                  : matches1450
                  ? 600
                  : matches1180
                  ? 500
                  : 450,
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MakeAnOffer;
