import Box from "@mui/material/Box";
import { useScreenBreakPoints } from "../../theme";

import img1 from "../../utils/assets/sepetfast/img-1.webp";
import img2 from "../../utils/assets/sepetfast/img-2.webp";
import img3 from "../../utils/assets/sepetfast/img-3.webp";
import img4 from "../../utils/assets/sepetfast/img-4.webp";
import img5 from "../../utils/assets/sepetfast/img-5.webp";
import img6 from "../../utils/assets/sepetfast/img-6.webp";
import bgEllipse from "../../utils/assets/bg-ellipse.webp";
import bg from "../../utils/assets/why-sepettakip/bg.webp";
import sepetBg from "../../utils/assets/sepetapp-bg.webp";
import ellipse from "../../utils/assets/ellipse.webp";

export default function SepetFast() {
  const { xxlScreen, xlScreen, lgScreen, mdScreen, smScreen } =
    useScreenBreakPoints();

  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f5",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={bgEllipse}
        alt="ellipse"
        style={{ position: "absolute", left: "-40%", opacity: 0.7, zIndex: 1 }}
      />
      <h1
        style={{
          fontSize: xxlScreen
            ? "60px"
            : xlScreen
            ? "50px"
            : lgScreen
            ? "40px"
            : "24px",
          fontFamily: "ubuntuB",
          fontWeight: "bold",
          color: "#24233a",
          marginBottom: 10,
          textAlign: "center",
          maxWidth: 800,
          margin: "auto",
          marginTop: 70,
        }}
      >
        Taşımacılık Hizmeti Veren Şirketler İçin Etkili Çözüm!
      </h1>

      <Box
        sx={{
          zIndex: 5,
          position: "relative",
          display: "flex",
          flexDirection: mdScreen ? "row" : "column",
          padding: mdScreen ? "0px 150px" : "0px 20px",
          justifyContent: "center",
        }}
      >
        <Box>
          <img
            src={img1}
            alt="img-1"
            style={{ height: "auto", maxWidth: "100%" }}
          />
          <h2
            style={{
              fontSize: xxlScreen ? 40 : xlScreen ? 35 : lgScreen ? 30 : 20,
              fontWeight: "bold",
              fontFamily: "ubuntuB",
              color: "#24233a",
              marginBottom: 20,
            }}
          >
            Bayileriniz İçin Efektif Özellikler
          </h2>
          <ul
            style={{
              fontSize: xxlScreen ? 30 : xlScreen ? 25 : lgScreen ? 20 : 16,
              color: "#24233a",
              fontFamily: "ubuntuB",
              paddingLeft: mdScreen ? "40px" : "20px",
            }}
          >
            <li>Çevredeki müsait kurye sayısını görüntüleme</li>
            <li>Kurye çağırma</li>
            <li>QR kod ile teslim etme</li>
            <li>Kurye teslim edildi bilgisini görme</li>
            <li>Gün sonu hesabını takip etme</li>
            <li>Masterpass güvencesiyle ödeme</li>
          </ul>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: mdScreen ? "center" : "flex-start",
            }}
          >
            <img
              alt="img-2"
              src={img2}
              style={{ height: "auto", maxWidth: "100%" }}
            />
          </Box>
          <h2
            style={{
              fontSize: xxlScreen ? 40 : xlScreen ? 35 : lgScreen ? 30 : 20,
              fontWeight: "bold",
              fontFamily: "ubuntuB",
              marginBottom: 20,
              color: "#24233a",
            }}
          >
            Kuryeleriniz İçin Efektif Özellikler
          </h2>
          <ul
            style={{
              fontSize: xxlScreen ? 30 : xlScreen ? 25 : lgScreen ? 20 : 16,
              color: "#24233a",
              paddingLeft: mdScreen ? "40px" : "20px",
              fontFamily: "ubuntuB",
            }}
          >
            <li>Siparişi Kabul etme / Reddetme</li>
            <li>Yola çıkarma / Teslim etme</li>
            <li>QR ile veya sipariş no ile paketi üzerine alma</li>
            <li>Müşteriyi tek tuşla arama</li>
            <li>Müşteri adresini tek tuşla navigasyona aktarma</li>
            <li>Paket yoğunluklu bölgeleri görme</li>
          </ul>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: mdScreen ? "row" : "column",
          width: mdScreen ? "80%" : "90%",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          zIndex: 5,
          marginTop: mdScreen ? 10 : 4,
          marginBottom: mdScreen ? 10 : 4,
        }}
      >
        <Box sx={{ maxWidth: "600px", marginRight: mdScreen ? 10 : 0 }}>
          <h1
            style={{
              fontSize: xxlScreen
                ? "60px"
                : xlScreen
                ? "50px"
                : lgScreen
                ? "40px"
                : "24px",
              fontFamily: "ubuntuB",
              fontWeight: "bold",
              color: "#24233a",
              marginBottom: 10,
              textAlign: mdScreen ? "left" : "center",
            }}
          >
            Siparişlerinizi Kolayca Takip Edin!
          </h1>
          <ul
            style={{
              fontSize: mdScreen ? 30 : 18,
              paddingLeft: mdScreen ? "40px" : "20px",
              color: "#24233a",
              fontFamily: "ubuntuR",
              fontWeight: 500,
            }}
          >
            <li>Haritada kuryenizi canlı izleyin.</li>
            <li>Paket yoğunluklu bölgeleri görüntüleyin.</li>
            <li>Aktarılan / Teslim edilen paketleri anlık görüntüleyin.</li>
          </ul>
        </Box>

        <Box>
          <img
            src={img3}
            alt="img-3"
            style={{ height: "auto", maxWidth: "100%" }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: mdScreen ? "row" : "column-reverse",
          width: mdScreen ? "80%" : "90%",
          margin: "auto",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <img
            src={img4}
            alt="img-4"
            style={{ height: "auto", maxWidth: "100%" }}
          />
        </Box>

        <Box sx={{ marginLeft: 10 }}>
          <h1
            style={{
              fontSize: xxlScreen
                ? "60px"
                : xlScreen
                ? "50px"
                : lgScreen
                ? "40px"
                : "24px",
              fontFamily: "ubuntuB",
              fontWeight: "bold",
              color: "#24233a",
              marginBottom: mdScreen ? 40 : 20,
            }}
          >
            Kuryelerinizi ve Şubelerinizi Kolayca Ekleyin!
          </h1>
          <Box sx={{ maxWidth: 400 }}>
            <p
              style={{
                fontSize: mdScreen ? 30 : 20,
                color: "#24233a",
                fontFamily: "ubuntuR",
                marginBottom: 20,
                fontWeight: 300,
              }}
            >
              Şubelerle anlaştığınız taşıma ücretlerini girin
            </p>
            <ul
              style={{
                fontSize: mdScreen ? 30 : 20,
                color: "#24233a",
                paddingLeft: mdScreen ? "40px" : "10px",
                fontFamily: "ubuntuB",
                fontWeight: 500,
              }}
            >
              <li style={{ listStylePosition: "inside" }}>Paket başı </li>
              <li style={{ listStylePosition: "inside" }}>Km bazında</li>
              <li style={{ listStylePosition: "inside" }}>Paket başı+km</li>
            </ul>
          </Box>
        </Box>
      </Box>

      <Box sx={{ position: "relative", marginTop: 5 }}>
        <img
          src={bg}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
          alt="img-bg"
        />

        <Box
          sx={{ width: "80%", margin: "auto", paddingTop: 5, marginBottom: 10 }}
        >
          <ul
            style={{
              fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
              fontFamily: "ubuntuR",
              fontWeight: 300,
              paddingLeft: mdScreen ? "40px" : "10px",
              color: "#24233a",
            }}
          >
            <li style={{ marginBottom: 5 }}>
              Paketleri kuryeler üzerinde belli aralıklarla ve sırayla dağıtın
              veya hepsini aynı anda yönlendirin.
            </li>
            <li style={{ marginBottom: 5 }}>
              Bayilerle anlaştığınız taşıma ücretlerini online tahsil edin ve
              tarafınıza günlük ödeme olarak alın.
            </li>
            <li style={{ marginBottom: 5 }}>
              “Hızlı Çağır” özelliği ile bayilerinze gönderi bilgilerini
              girmeden kurye çağırma olanağı sunun.
            </li>
            <li style={{ marginBottom: 5 }}>
              Bayilerinizle SepetTakip Entegrasyon paketi ile veya sadece “Kurye
              Çağır” App’i ile çalışın.
            </li>
            <li style={{ marginBottom: 5 }}>
              Taşıma ücretleri, tahsilat ve ödemelerini istediğiniz vade
              aralıklarına göre ayarlayın.
            </li>
            <li style={{ marginBottom: 5 }}>
              Hangi iptal durumlarında ücret alınır veya alınmaz belirleyin.
            </li>
            <li style={{ marginBottom: 5 }}>
              İsterseniz gönderi başına ücretleri online olarak tahsil edin.
            </li>
            <li style={{ marginBottom: 5 }}>
              Kuryelerinizin alacağı ücretleri girin.
            </li>
            <li>Kuryelerinize hızlı bölge atayın.</li>
          </ul>
        </Box>

        <Box sx={{ position: "relative", zIndex: 7 }}>
          <img
            alt="sepet-bg"
            src={sepetBg}
            style={{
              position: "absolute",
              top: -300,
              zIndex: 2,
              height: "auto",
              maxWidth: "100%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column-reverse",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              justifyContent: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box>
              <img
                src={img5}
                alt="img-5"
                style={{ height: "auto", maxWidth: "100%" }}
              />
            </Box>

            <Box sx={{ maxWidth: "600px", marginLeft: mdScreen ? 10 : 0 }}>
              <h1
                style={{
                  fontSize: xxlScreen
                    ? "60px"
                    : xlScreen
                    ? "50px"
                    : lgScreen
                    ? "40px"
                    : "32px",
                  fontFamily: "ubuntuB",
                  fontWeight: "bold",
                  color: "#24233a",
                  marginBottom: "32px",
                  textAlign: mdScreen ? "right" : "center",
                }}
              >
                Analizler
              </h1>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                  textAlign: mdScreen ? "right" : "center",
                }}
              >
                Şube ve kurye raporlarınızı detaylı olarak görüntüleyin,
                analizlerinizi kolayca yaparak işinizi geliştirin.
              </p>
            </Box>
          </Box>
        </Box>

        <Box sx={{ position: "relative", marginBottom: 12, zIndex: 5 }}>
          <img
            src={ellipse}
            alt="ellipse"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              display: mdScreen ? "block" : "none",
              transform: "translate(50%, -50%)",
              zIndex: 1,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              marginTop: mdScreen ? "auto" : 5,
              justifyContent: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box sx={{ maxWidth: "600px", marginRight: mdScreen ? 10 : 0 }}>
              <h1
                style={{
                  fontSize: xxlScreen
                    ? "60px"
                    : xlScreen
                    ? "50px"
                    : lgScreen
                    ? "40px"
                    : "24px",
                  fontFamily: "ubuntuB",
                  fontWeight: "bold",
                  color: "#24233a",
                  marginBottom: "32px",
                  textAlign: mdScreen ? "left" : "center",
                }}
              >
                Ödemeler
              </h1>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                  textAlign: mdScreen ? "left" : "center",
                }}
              >
                Alacak ve verecek ödemelerinizi kolayca yönetin.
              </p>
            </Box>

            <Box>
              <img
                src={img6}
                alt="img-6"
                style={{ height: "auto", maxWidth: "100%" }}
              />
            </Box>
          </Box>

          <h1
            style={{
              fontSize: xxlScreen
                ? "60px"
                : xlScreen
                ? "50px"
                : lgScreen
                ? "40px"
                : "24px",
              fontFamily: "ubuntuB",
              fontWeight: "bold",
              color: "#24233a",
              marginBottom: "32px",
              fontStyle: "italic",
              textAlign: mdScreen ? "center" : "right",
              marginTop: 20,
              position: "relative",
              zIndex: 5,
              width: "80%",
              marginLeft: "auto",
              marginRight: mdScreen ? "auto" : "20px",
            }}
          >
            Artık İş Hacminizi Büyütmeye Hazırsınız!
          </h1>
        </Box>
      </Box>
    </Box>
  );
}
