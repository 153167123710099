import React, { useState, useEffect, useContext } from "react";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev2";
import Select from "react-select";
import RegistrationField from "../../Registration/registrationField";
import { RegistrationContext } from "../../../contexts/registrationContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "@mui/material";
import validator from "validator";
import { useToast } from "../../../components/error";
import { PaymentContext } from "../../../contexts/paymentContext";

const eInvoices = [
  {
    value: true,
    label: "Evet",
  },
  {
    value: false,
    label: "Hayır",
  },
];

const companyTypes = [
  { value: "Şahıs Şirketi", label: "Şahıs Şirketi" },
  { value: "Anonim Şirket", label: "Anonim Şirket" },
  { value: "Limited Şirket", label: "Limited Şirket" },
  { value: "Adi Ortaklık", label: "Adi Ortaklık" },
];

const Company = ({ matches, code }) => {
  const { alert, success } = useToast();
  const [formData, setFormData] = useState(null);
  const [selectedCompanyType, setselectedCompanyType] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [selectedNeigh, setSelectedNeigh] = useState(null);
  const { getCityFunc, cityy, getTownFunc, townn, getNeighFunc, neighh } =
    useContext(RegistrationContext);
  const { offerBillingFunc, offerBillingList } = useContext(PaymentContext);
  const [isInvoices, setIsInvoices] = useState(null);

  useEffect(() => {
    if (code != null && offerBillingList !== null) {
      setselectedCompanyType({
        value: offerBillingList?.company_type,
        label: offerBillingList?.company_type,
      });
      if (offerBillingList?.billing_city !== null) {
        setSelectedCity({
          id: offerBillingList?.billing_city?.id,
          name: offerBillingList?.billing_city?.name,
        });
      }
      if (offerBillingList?.billing_town !== null) {
        setSelectedTown({
          id: offerBillingList?.billing_town?.id,
          name: offerBillingList?.billing_town?.name,
        });
      }
      if (offerBillingList?.billing_neighborhood !== null) {
        setSelectedNeigh({
          id: offerBillingList?.billing_neighborhood?.id,
          name: offerBillingList?.billing_neighborhood?.name,
        });
      }
      setIsInvoices({
        value: offerBillingList?.efatura_mukellef,
        label: offerBillingList?.efatura_mukellef ? "Evet" : "Hayır",
      });
      setFormData({
        code: code,
        company_type: offerBillingList?.company_type
          ? offerBillingList?.company_type
          : "",
        tax_authority: offerBillingList?.tax_authority
          ? offerBillingList?.tax_authority
          : "",
        tax_no: offerBillingList?.tax_no ? offerBillingList?.tax_no : "",
        identity_number: offerBillingList?.identity_number
          ? offerBillingList?.identity_number
          : "",
        company_title: offerBillingList?.company_title
          ? offerBillingList?.company_title
          : "",
        billing_address: offerBillingList?.billing_address
          ? offerBillingList?.billing_address
          : "",
        billing_city: offerBillingList?.billing_city
          ? offerBillingList?.billing_city?.id
          : null,
        billing_town: offerBillingList?.billing_town
          ? offerBillingList?.billing_town?.id
          : null,
        billing_neighborhood: offerBillingList?.billing_neighborhood
          ? offerBillingList?.billing_neighborhood?.id
          : null,
        billing_phone: offerBillingList?.billing_phone
          ? offerBillingList?.billing_phone
          : "90",
        billing_email: offerBillingList?.billing_email
          ? offerBillingList?.billing_email
          : "",
        billing_email: offerBillingList?.billing_email
          ? offerBillingList?.billing_email
          : "",
        efatura_mukellef:
          offerBillingList?.efatura_mukellef !== null
            ? offerBillingList?.efatura_mukellef
            : null,
      });
    }
  }, [code, offerBillingList]);

  useEffect(() => {
    getCityFunc();
  }, []);

  useEffect(() => {
    if (selectedCity != null) {
      getTownFunc(selectedCity.id);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedTown != null) {
      getNeighFunc(selectedCity.id, selectedTown?.id);
    }
  }, [selectedTown]);

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  const sendOfferBilling = () => {
    if (isBlank(formData?.company_type)) {
      alert("Şirket türü boş bırakılamaz.");
    } else if (isBlank(formData?.tax_authority)) {
      alert("Vergi dairesi boş bırakılamaz.");
    } else if (isBlank(formData?.tax_no)) {
      alert("Vergi no boş bırakılamaz.");
    } else if (formData?.tax_no?.match(/\d/g).length != 10) {
      alert("Vergi numarasının 10 haneli olduğundan emin olunuz.");
    } else if (isBlank(formData?.identity_number)) {
      alert("Kimlik no boş bırakılamaz.");
    } else if (formData?.identity_number.match(/\d/g).length != 11) {
      alert("Lütfen geçerli 11 haneli tc kimlik numaranızı giriniz.");
    } else if (isBlank(formData?.company_title)) {
      alert("Ticari ünvan boş bırakılamaz.");
    } else if (formData?.billing_city == null) {
      alert("İl seçimi boş bırakılamaz.");
    } else if (formData?.billing_town == null) {
      alert("İlçe seçimi boş bırakılamaz.");
    } else if (formData?.billing_neighborhood == null) {
      alert("Mahalle seçimi boş bırakılamaz.");
    } else if (isBlank(formData?.billing_address)) {
      alert("Adres boş bırakılamaz.");
    } else if (!validator.isEmail(formData?.billing_email)) {
      alert("Uygun bir email giriniz.");
    } else if (!validator.isMobilePhone(formData?.billing_phone, "tr-TR")) {
      alert("Uygun bir cep telefonu giriniz.");
    } else if (formData?.efatura_mukellef === null) {
      alert("E-Fatura mükellefi seçimi boş bırakılamaz.");
    } else {
      offerBillingFunc(formData);
    }
  };

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ marginTop: matches ? 30 : 0, width: "95%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
          marginTop: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 14,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "ubuntuM",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 10,
              marginBottom: matches ? 10 : 5,
            }}
          >
            Şirket Türü
          </span>
          <Select
            options={companyTypes}
            styles={selectDefaultStyles}
            isSearchable={false}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            dynamiczIndex={4}
            value={selectedCompanyType}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                company_type: e.value,
              });
              setselectedCompanyType(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.label}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            marginTop: matches ? 0 : 10,
            flex: 1,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"Vergi Dairesi"}
            fieldValue={formData?.tax_authority}
            fieldName={"tax_authority"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            marginTop: matches ? 0 : 10,
            flex: 1,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"Vergi No"}
            fieldValue={formData?.tax_no}
            fieldName={"tax_no"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"T.C Kimlik Numarası"}
            fieldValue={formData?.identity_number}
            fieldName={"identity_number"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"Ticari Ünvan"}
            fieldValue={formData?.company_title}
            fieldName={"company_title"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "ubuntuM",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            İl Seçimi
          </span>
          <Select
            options={cityy}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            dynamiczIndex={3}
            value={selectedCity}
            getOptionValue={(option) => option.id}
            onChange={(e) => {
              setSelectedCity(e);
              setFormData({ ...formData, billing_city: e.id });
              setSelectedTown(null);
              setSelectedNeigh(null);
            }}
            filterOption={customFilter}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "ubuntuM",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            İlçe Seçimi
          </span>
          <Select
            options={townn}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={selectedTown}
            dynamiczIndex={2}
            getOptionValue={(option) => option.id}
            filterOption={customFilter}
            onChange={(e) => {
              setFormData({ ...formData, billing_town: e.id });
              setSelectedTown(e);
              setSelectedNeigh(null);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "ubuntuM",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            Mahalle Seçimi
          </span>
          <Select
            options={neighh}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={selectedNeigh}
            dynamiczIndex={1}
            getOptionValue={(option) => option.id}
            onChange={(e) => {
              setFormData({ ...formData, billing_neighborhood: e.id });
              setSelectedNeigh(e);
            }}
            filterOption={customFilter}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
        }}
      >
        <div
          style={{
            flex: 1,
            marginTop: matches ? 20 : 10,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"Adres"}
            fieldValue={formData?.billing_address}
            fieldName={"billing_address"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginTop: matches ? 20 : 10,
          }}
        >
          <RegistrationField
            width={"90%"}
            fieldHeader={"Email"}
            fieldValue={formData?.billing_email}
            fieldName={"billing_email"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div style={{ flex: 1, marginTop: matches ? 20 : 5 }}>
          <span
            style={{
              color: "#293c51",
              fontFamily: "ubuntuM",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
            }}
          >
            Cep No
          </span>
          <div
            style={{
              flex: 1,
              backgroundColor: "#f4f4f4",
              height: 45,
              marginTop: matches ? 10 : 5,
              borderRadius: 6,
              marginRight: 14,
            }}
          >
            <PhoneInput
              countryCodeEditable={false}
              country="tr"
              onlyCountries={["tr"]}
              localization={{ tr: "Türkiye" }}
              value={formData?.billing_phone}
              containerStyle={{ height: 45 }}
              inputStyle={{
                height: 45,
                width: "100%",
                fontSize: 15,
                fontWeight: "400",
                fontFamily: "ubuntuL",
              }}
              onChange={(phone) => {
                setFormData({ ...formData, billing_phone: phone });
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              fontFamily: "segoeuiRg",
              color: "#293c51",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            E-Fatura Mükellefi mi?
          </span>
          <Select
            options={eInvoices}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={isInvoices}
            dynamiczIndex={1}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setFormData({ ...formData, efatura_mukellef: e.value });
              setIsInvoices(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.label}</span>
              </div>
            )}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: 30,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => {
            sendOfferBilling();
          }}
          style={{
            backgroundColor: "#293c51",
            textTransform: "capitalize",
            borderRadius: 13,
            fontFamily: "ubuntuL",
            width: 180,
            height: 36,
            borderColor: "gray",
            border: "solid",
            fontSize: 18,
          }}
          size="small"
          variant="contained"
        >
          Devam Et
        </Button>
      </div>
    </div>
  );
};

export default Company;
