import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
//Mui
import { Box, Button } from "@mui/material";
//png
import gözatIcon from "../../../../utils/assets/gözat_icon.webp";

import ReactPlayer from "react-player/lazy";
import { makeStyles } from "@mui/styles";
import { theme, useScreenBreakPoints } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
  video: {
    "& video": {
      clipPath: "inset(3px 2px)",
    },
  },
}));

function AllOrderOnePanel() {
  let history = useHistory();
  const classes = useStyles();
  const { smScreen, mdScreen, lgScreen, xlScreen } = useScreenBreakPoints();

  return (
    <Box
      sx={{
        width: "100%",
        height: !smScreen ? "60vh" : "calc(100vh - 60px)",
        backgroundColor: "#030213",
        display: "flex",
        justifyContent: "space-between",
        marginTop: smScreen ? "60px" : "56px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: !smScreen ? "column" : "row",
          alignItems: "center",
          justifyContent: !smScreen ? "" : "flex-end",
          marginTop: !smScreen ? 40 : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: !smScreen ? "center" : "",
            justifyContent: !smScreen ? "center" : "",
            height: "60%",
          }}
        >
          <span
            style={{
              color: "#fff",
              lineHeight: !smScreen ? "1.2" : "1.5",
              fontSize: xlScreen ? 50 : lgScreen ? 40 : smScreen ? 30 : 30,
              textAlign: !smScreen ? "center" : "",
            }}
          >
            Tüm Siparişler <br /> Tek Panelde
          </span>
          <span
            style={{
              color: "#fff",
              lineHeight: !smScreen ? "1.2" : "1.67",
              fontSize: xlScreen ? 24 : lgScreen ? 20 : smScreen ? 16 : 18,
              textAlign: !smScreen ? "center" : "",
            }}
          >
            Tüm Pazaryeri Entegrasyonlar; Masa <br /> Sipariş Yöntemi, Kurye
            Takip ve Stok <br /> Takip Çözümleri Tek Yerde!
          </span>

          <Button
            size="small"
            variant="contained"
            startIcon={
              smScreen ? (
                <img
                  src={gözatIcon}
                  style={{
                    width: "90%",
                    height: "90%",
                    maxHeight: 45,
                    maxWidth: 45,
                  }}
                ></img>
              ) : (
                ""
              )
            }
            onClick={() => {
              history.push("/take-a-look");
            }}
            style={{
              background: `linear-gradient(to right,  ${"#4E3B53"} 0%,${"#CB3E5F"} 100%)`,
              display: "flex",
              justifyContent: "space-around",
              cursor: "pointer",
              maxWidth: 200,
              maxHeight: 50,
              textTransform: "capitalize",
              fontSize: lgScreen ? 25 : smScreen ? 22 : 12,
              fontFamily: "ubuntuM",
              marginTop: 3,
            }}
          >
            Göz At
          </Button>
        </div>

        <ReactPlayer
          url={
            "https://static.sepettakip.com/st-web-assets/TumSiparislerTekPanelde.mp4"
          }
          loop={true}
          playing={true}
          muted
          width={smScreen ? "70%" : "100%"}
          height={"100%"}
          className={classes.video}
          playsinline
        />
      </div>
    </Box>
  );
}

export default AllOrderOnePanel;
