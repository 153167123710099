export const selectDefaultStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#DCDCDC",
    borderRadius: 6,
    width: state.selectProps.selectWidth,
    color: "#fff",
    border: 0,
    cursor: "pointer",
    boxShadow: "none",
    height: 45,
  }),
  container: (styles, state) => ({
    ...styles,
    width: state.selectProps.selectWidth,
    zIndex: state.selectProps.testIndex,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "ubuntuM",
    fontSize: 15,
  }),
  dropdownIndicator: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: "black",
  }),
  indicatorSeparator: (styles, { isFocused, isSelected }) => ({
    ...styles,
    display: "none",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "black",
    fontSize: 15,
    fontWeight: "400",
    fontFamily: "ubuntuL",
  }),
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "baige",
      color: "black",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      overflow: "overlay",
      fontFamily: "ubuntuR",
      ":active": {
        ...styles[":active"],
        backgroundColor: "red",
      },
      ":hover": {
        ...styles[":hover"],
        backgroundColor: state.selectProps.hoverColor,
        color: "white",
      },
    };
  },
};
