import { FileDownload } from "@mui/icons-material";
import {
  Box,
  useMediaQuery,
  createTheme,
  Button,
  InputBase,
} from "@mui/material";
import { useEffect, useState } from "react";
import RegistrationField from "../Registration/registrationField";

export default function Download() {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesXl = useMediaQuery("(min-width:1020px)");
  const [formData, setFormData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setFormData({
      full_name: "",
      email: "",
    });
  }, []);

  useEffect(() => {
    if (formData?.full_name && formData?.email) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  return (
    <Box
      sx={{
        height: matches ? "100vh" : "auto",
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 100px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: "300px",
          maxWidth: 800,
          margin: "auto",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontFamily: "ubuntuB", fontSize: 44, marginBottom: 24 }}>
          SepetTakip İndir
        </h1>
        <p style={{ fontFamily: "PopMed", fontSize: 24, marginTop: 0 }}>
          SepetTakip ile tüm siparişlerini tek ekrandan yönetmek çok kolay!
          Kurulum için hemen tıkla, en güncel sürümü indirmeye başla!
        </p>
        <div style={{ display: "flex", marginBottom: 20, width: "100%" }}>
          <div style={{ width: matchesXl ? "50%" : "100%", textAlign: "left" }}>
            <RegistrationField
              width={"60%"}
              fieldHeader={"Adınız Soyadınız"}
              fieldName={"full_name"}
              fieldValue={formData?.full_name}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
          <div style={{ width: matchesXl ? "50%" : "100%", textAlign: "left" }}>
            <RegistrationField
              width={"60%"}
              fieldHeader={"E-posta"}
              fieldName={"email"}
              fieldValue={formData?.email}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </div>
        <Button
          disabled={isDisabled}
          style={{
            textTransform: "capitalize",
            backgroundColor: "#f53b62",
            fontFamily: "ubuntuM",
            marginLeft: "auto",
          }}
          onClick={() => {
            window.open(`https://api.sepettakip.com/download`, "_blank");
          }}
          variant="contained"
          startIcon={<FileDownload />}
        >
          Sepettakip İndir
        </Button>
      </Box>
    </Box>
  );
}
