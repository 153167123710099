import { Box, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { createTheme, styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useHistory, useParams } from "react-router-dom";
import Iframe from "react-iframe";

import MakeAnOffer from "../Offers/components/makeAnOffer";
import { PaymentContext } from "../../contexts/paymentContext";

import Company from "../Offers/components/company";
import SuccessPayed from "../Offers/components/successPayed";
import FailedPayed from "../Offers/components/failedPayed";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "green",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "green",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "green",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const OffersV2 = () => {
  let history = useHistory();
  let { id, code } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    iyzicoFormFunc,
    iyzicoForm,
    offerCodeFunc,
    offerDetail,
    offerBillingListFunc,
  } = useContext(PaymentContext);

  useEffect(() => {
    offerCodeFunc(code);
    offerBillingListFunc(code);
  }, []);

  function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = [
    "Teklif Onay",
    "Fatura Bilgileri",
    "Ödeme Ekranı",
    "Ödeme Sonuç",
  ];
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f53f62",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#f53f62",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="main"
          sx={{
            width: "95%",
            height: "98%",
            backgroundColor: "#fff",
            borderRadius: 2,
            overflow: "auto",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: matches ? 0 : 50,
            }}
          >
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={parseInt(id)}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      <span
                        onClick={() => {
                          if (label == "Teklif Onay") {
                            history.push(`/offers_v2/0/${code}/q`);
                          } else if (label == "Fatura Bilgileri") {
                            if (id != 0) {
                              history.push(`/offers_v2/1/${code}/q`);
                            }
                          } else if (label == "Ödeme Ekranı") {
                            if (id != 0 && id != 1) {
                              history.push(`/offers_v2/2/${code}/q`);
                            }
                          }
                        }}
                        style={{
                          fontFamily: "ubuntuB",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {label}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>

            <div
              style={{
                height: "100%",
                width: "100%",
                //  alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {id == 0 ? (
                <MakeAnOffer
                  matches={matches}
                  code={code}
                  offerDetail={offerDetail}
                />
              ) : id == 1 ? (
                <Company
                  matches={matches}
                  iyzicoFormFunc={iyzicoFormFunc}
                  code={code}
                />
              ) : id == 2 ? (
                iyzicoForm?.paymentPageUrl && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "none",
                      height: "100%",
                    }}
                  >
                    <Iframe
                      url={iyzicoForm?.paymentPageUrl + "&iframe=true"}
                      //width={"40%"}
                      width={"100%"}
                      height={"98%"}
                      frameBorder={0}
                    />
                  </div>
                )
              ) : id == 3 ? (
                <SuccessPayed />
              ) : id == 4 ? (
                <FailedPayed />
              ) : (
                ""
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default OffersV2;
