import React from "react";
import {
  Box,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";

import { referenceImages } from "../../utils/assets/references/";

const References = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: matches ? "165px" : "100px",
        marginBottom: "50px",
      }}
    >
      <h3
        style={{
          color: "#293c51",
          fontSize: "34px",
          marginBottom: "32px",
          fontFamily: "ubuntuB",
        }}
      >
        Referanslarımız
      </h3>
      <Grid container spacing={4}>
        {referenceImages.map((image, i) => (
          <Grid item xs={4} md={2} xl={1.6} key={i}>
            <img
              src={image}
              style={{ maxWidth: "100%", height: "auto" }}
              alt={`reference-${i}`}
            />
          </Grid>
        ))}
      </Grid>
      <h4
        style={{
          marginTop: "74px",
          fontSize: "24px",
          color: "#293c51",
          marginBottom: "16px",
          fontFamily: "ubuntuB",
        }}
      >
        Diğer Referanslarımız
      </h4>
      <Typography
        paragraph={true}
        sx={{
          fontSize: "18px",
          lineHeight: 1.7,
          color: "#293c51",
          fontFamily: "ubuntuL",
        }}
      >
        Çıtır Elazığ Pidecisi ● Öz Adana Kebapçısı ● Fındıkzade Mevlana Pide ●
        Bizim Kebap ● Pide Sepeti ● Diyette ● Gözde Kanat ● Beyaz Ev Restaurant
        Ev Yemekleri ● Börek Chi Sefa ● Avrasya Pilavcısı ● Kamil Usta Kella
        Paça ● Köfteci Yusuf Usta ● Gül Mantı Cafe ● Chicken 216 ● Aydın Büfe ●
        Urfalı Ali Usta ● Öz Anadolu Kebap ● Kem Küm Cafe ● Öz Ocak Kebap
        Lahmacun ve Pide Salonu ● Sedir Pide & Lahmacun ● Keban Börek ● Lokma
        Taş Fırın ● Ağacan 63 ● Efsane Pasta Cafe ● Ela Kebap ● Nurimom Zurna ●
        Öz Karadeniz Pide & Kebap Salonu ● Bi Waffle ● Çiğ Köfteci Ali Ustam ●
        Şahmeran Döner & Lahmacun ● Kuba Pasta Cafe & Restaurant ● Güveç Ustası
        ● Fenerbahçe İşkembe Salonu ● Ayy Aşırı Tatlı Profiterol ● Fıstıkâlâ
        Gaziantep Baklava ● İnci Kahvaltı & Fastfood || İnci Kebap ● Sanoğlu
        Büfe ● Kanatçı Onur{" "}
        <span style={{ fontSize: "14px", fontFamily: "ubuntuL" }}>
          ... +1000
        </span>
      </Typography>
    </Box>
  );
};

export default References;
