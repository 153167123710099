import { http } from "./axios";

function get_city() {
  return http.get(`/officeuser/city`);
}

function get_town(city_id) {
  return http.get(`/officeuser/town?city=${city_id}`);
}

function get_neigh(city_id, town_id) {
  return http.get(`/officeuser/neighborhood?city=${city_id}&town=${town_id}`);
}

function get_application_sector() {
  return http.get(`/restaurant/application/sectors`);
}

function registration(data) {
  return http.post(`/restaurant/application`, data);
}

function contact_us(data) {
  return http.post(`/contact`, data);
}

function get_campaign(type, code) {
  return http.get(`/restaurant/campaign/${type}/${code}`);
}

function send_campaign(type, code) {
  return http.patch(`/restaurant/campaign/${type}/${code}`);
}

export default {
  get_application_sector,
  registration,
  contact_us,
  get_campaign,
  send_campaign,
  get_city,
  get_town,
  get_neigh,
};
