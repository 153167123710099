import React from "react";
import SEO from "../../../../components/seo";
import contractPdf from "../../../../utils/Hizmetİptaliveİade.pdf";

const Hızmet = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <SEO
        title="SepetTakip | Sözleşme"
        description="Sepettakip Sözleşmeler"
        name="Sepettakip"
        type="article"
      />
      <embed
        src={contractPdf}
        type="application/pdf"
        style={{ width: "100%", height: "99vh" }}
      />
    </div>
  );
};

export default Hızmet;
