import { Button, useMediaQuery } from "@mui/material";
import { Box, createTheme } from "@mui/system";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import registrationService from "../../services/registrationService";
import sms1 from "../../utils/assets/smsImages/sms-01.webp";
import sms2 from "../../utils/assets/smsImages/sms-02.webp";
import { useHistory } from "react-router-dom";

export default function Campaigns() {
  let history = useHistory();
  const { type, code } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const getCampaign = async () => {
    await registrationService.get_campaign(type, code);
  };

  const submitCampaign = async () => {
    await registrationService.send_campaign(type, code).then((res) => {
      history.push("/completed-registration");
    });
  };

  useEffect(() => {
    getCampaign();
  }, []);

  return (
    <Box
      sx={{
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: matches ? "120px" : "70px",
        marginBottom: "50px",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img
          src={type === "gift" ? sms1 : sms2}
          alt={type}
          style={{
            marginBottom: 10,
            maxWidth: matches ? "350px" : "100%",
            height: "auto",
          }}
        />
        <Button
          onClick={submitCampaign}
          style={{
            margin: "auto",
            backgroundColor: "#293c51",
            textTransform: "capitalize",
            fontFamily: "ubuntuL",
            height: 42,
            fontSize: 18,
            display: "flex",
            justifyContent: "space-evenly",
          }}
          size="small"
          variant="contained"
        >
          İletişime Geç
        </Button>
      </Box>
    </Box>
  );
}
