import React from "react";
import lottie from "lottie-web";
import completedRegistrationLogo from "../../../utils/json/completedRegistration.json";
import { useEffect } from "react";
import { createTheme, useMediaQuery } from "@mui/material";

const SuccessPayed = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: completedRegistrationLogo,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <div
      style={{
        marginTop: matches ? 30 : 0,
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: matches ? "50%" : "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 200,
        }}
      >
        {" "}
        <div id="react-logo" style={{ width: "50%", height: "50%" }}></div>
        <div
          style={{
            fontFamily: "ubuntuB",
            fontSize: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Ödeme tamamlandı.
        </div>
      </div>
    </div>
  );
};

export default SuccessPayed;
