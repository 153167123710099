import { Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  LocationOn,
  Mail,
  Phone,
  InsertDriveFile,
  LinkedIn,
} from "@mui/icons-material";
import React from "react";
import { sepet_takip_2 } from "../../../../utils/assets";
import KvkkModal from "../../../../components/modals/kvkkModal";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as IyzLogo } from "../../../../utils/assets/logo_band_colored.svg";

export const Footer = ({ image = "", title, label }) => {
  const [kvkkModal, setKvkkModal] = useState(false);
  let history = useHistory();
  return (
    <Box
      sx={{
        display: { sx: "block", sm: "flex" },
        flexDirection: "column", // { sx: "column", sm: "row" },
        justifyContent: { sx: "center", sm: "space-around" },
        width: "100%",
        backgroundColor: "#1c1c1c",
      }}
    >
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          marginBottom: 24,
          width: "86%",
          borderBottomWidth: 0.5,
          marginLeft: "7%",
          borderBottomColor: "#d7d7d7",
          border: "solid",
          opacity: 0.4,
        }}
      />

      <Box
        sx={{
          display: { sx: "block", sm: "flex" },
          flexDirection: { sx: "column", sm: "row" },
          justifyContent: { sx: "space-evenly", sm: "space-between" },
          width: "84%",
          marginLeft: "7%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: 300,
          }}
        >
          <img
            src={sepet_takip_2}
            alt="sepet-takip"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: 260,
              height: 42,
            }}
          />
          <p style={{ width: 300, color: "#fff", fontSize: 13 }}>
            Tüm platformlardan gelen paket siparişlerinizi tek ekrandan takip
            edin, performansını ölçümleyin, raporlayın ve tüm sipariş
            sürecinizin verimini artırın.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: 180,
              marginBottom: 24,
              marginTop: 20,
            }}
          >
            <FacebookIcon
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `https://www.facebook.com/Sepet-Takip-100138979378165`,
                  "_blank"
                );
              }}
            />
            <TwitterIcon
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => {
                window.open(`https://twitter.com/SepetTakip`, "_blank");
              }}
            />
            <InstagramIcon
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => {
                window.open(`https://www.instagram.com/sepettakip/`, "_blank");
              }}
            />
            <YouTubeIcon
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => {
                window.open(`https://www.youtube.com/@sepettakip`, "_blank");
              }}
            />
            <LinkedIn
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `https://www.linkedin.com/company/83497584/admin/`,
                  "_blank"
                );
              }}
            />
          </div>
          <div style={{ fontSize: 11, marginBottom: 10 }}>
            Copyright{" "}
            <span style={{ fontWeight: "bold" }}>
              Sepet Takip Yaz. Tek A.Ş. 2022
            </span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "100%",
              fontFamily: "ubuntuL",
              fontWeight: 600,
              color: "#fff",
              fontSize: 16,
              lineHeight: 1.13,
            }}
          >
            İletişim
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <img src="/logo512.png" alt="logo" width={24} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              SepetTakip Yazılım Teknolojileri Anonim Şirketi
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <LocationOn style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              Kazlıçeşme Mah. 245. Sk No: 5 PK. 34010 Zeytinburnu,
              İstanbul/Türkiye
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <Phone style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontSize: 13,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              0850 255 20 04
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <Mail style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontSize: 13,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              info@sepettakip.com
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <InsertDriveFile style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              <span
                onClick={() => {
                  // setKvkkModal(true);
                  history.push("kvkk");
                }}
                style={{
                  textDecoration: "underline",
                  fontFamily: "ubuntuM",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                Kişisel Verilerin Korunması
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
              color: "#fff",
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <InsertDriveFile style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              <span
                onClick={() => {
                  //setKvkkModal(true);
                  history.push("st-gizlilik");
                }}
                style={{
                  textDecoration: "underline",
                  fontFamily: "ubuntuM",
                  color: "#fff",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                Gizlilik politikası
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <InsertDriveFile style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              <span
                onClick={() => {
                  // setKvkkModal(true);
                  history.push("hizmet");
                }}
                style={{
                  textDecoration: "underline",
                  fontFamily: "ubuntuM",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                Teslimat ve iade bildirimi
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
              color: "#fff",
            }}
          >
            <div
              style={{
                marginRight: 10,
              }}
            >
              <InsertDriveFile style={{ color: "#fff" }} />
            </div>
            <div
              style={{
                width: "90%",
                fontFamily: "ubuntuL",
                fontSize: 14,
                marginLeft: 5,
                color: "#fff",
              }}
            >
              <span
                onClick={() => {
                  // setKvkkModal(true);
                  history.push("st-kk");
                }}
                style={{
                  textDecoration: "underline",
                  fontFamily: "ubuntuM",
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                Kullanım Kosulları
              </span>
            </div>
          </div>
        </div>
      </Box>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <IyzLogo
          fill={"#547d5a"}
          style={{ width: "100%", height: 60, marginTop: 3 }}
        />
      </div>

      <KvkkModal kvkkModal={kvkkModal} setKvkkModal={setKvkkModal} />
    </Box>
  );
};

const styles = {
  item: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 16,
  },
  item_img: {
    width: 145,
    heigth: 160,
    justifyContent: "center",
    alignItems: "center",
  },
  item_lbl: {
    fontSize: 16,
    fontWeight: 400,
    color: "#293c51",
    width: 320,
    marginTop: 0,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  item_title: { fontSize: 24, fontWeight: 600, color: "#293c51" },
};
