import { createTheme, InputBase, useMediaQuery } from "@mui/material";
import React, { useState, useContext } from "react";

const RegistrationField = ({
  width,
  fieldHeader,
  fieldValue,
  fieldName,
  formData,
  setFormData,
}) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleChange = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newDataRest = { ...formData };

    newDataRest = { ...formData, [name]: valueData };

    setFormData(newDataRest);
  };

  return (
    <div style={{ width: width ? width : "60%" }}>
      <span
        style={{
          display: "block",
          color: "#293c51",
          fontFamily: "ubuntuM",
          fontSize: 15,
          fontWeight: 700,
          marginBottom: 10,
        }}
      >
        {fieldHeader}
      </span>
      <div
        style={{
          width: "100%",
          backgroundColor: "#DCDCDC",
          height: 45,
          borderRadius: 6,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: 8,
          }}
        >
          <InputBase
            variant="filled"
            style={{
              width: "100%",
              color: "black",
              marginLeft: 14,
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "ubuntuR",
              height: 45,
            }}
            value={fieldValue}
            onChange={handleChange(fieldName)}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationField;
