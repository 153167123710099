import React from "react";
import { Box, useMediaQuery, createTheme, Typography } from "@mui/material";

import whyUsBanner from "../../utils/assets/why-us-right.webp";
import whyUsBottom from "../../utils/assets/why-us-bottom.webp";

const WhyUs = () => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        width: "100%",
        background: "linear-gradient(119deg, #617e9d 1%, #809abd 100%)",
        paddingTop: matches ? 0 : "56px",
        display: "flex",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
          marginTop: matches ? "100px" : "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background: "white",
            paddingBottom: matches ? "0px" : "10px",
            width: matches ? "70%" : "100%",
            order: matches ? 1 : 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: matches ? "85px" : "20px",
              paddingBottom: matches ? "45px" : "20px",
              paddingRight: matches ? "135px" : "20px",
              paddingLeft: matches ? "75px" : "20px",
            }}
          >
            <span
              style={{
                fontFamily: "ubuntuB",
                fontSize: matches ? 30 : 20,
                color: "#293c51",
                marginBottom: matches ? "20px" : "15px",
              }}
            >
              Neden SepetTakip?
            </span>
            <span
              style={{
                fontFamily: "ubuntuL",
                fontSize: 15,
                color: "#293c51",
                lineHeight: 1.7,
                marginBottom: "20px",
              }}
            >
              SepetTakip, Yemeksepeti, Getir, Trendyol, Migros Yemek ve Gofody
              ile entegre çalışır. Gelen siparişleri tek ekran üzerinden size
              göstererek sipariş yönetiminde kolaylık sağlar. Tam anlamıyla
              işinizi kolaylaştırmak üzerine kurulu bir hizmet sunan SepetTakip,
              işletmenizin ve müşteri memnuniyetinizin verimini artırır.
            </span>
            <ul
              style={{
                fontFamily: "ubuntuL",
                fontSize: 15,
                color: "#293c51",
                lineHeight: 1.7,
                margin: "0px",
                marginBottom: "32px",
              }}
            >
              <li>Çoklu sipariş yönetimi imkanı sunar</li>
              <li>Zaman ve maliyetten tasarruf sağlama avantajı sunar</li>
              <li>
                Kurye atama ve tamamlanan siparişleri kuryeye aktarma imkanı
                sağlar
              </li>
              <li>
                Kolay raporlama ekranlarıyla sipariş ve sepet analizi yapabilme
                imkanı sağlar
              </li>
              <li>
                Gelecek dönem siparişlerini tahminleyerek, malzeme reçetelerinin
                ve satın alma emirlerinin oluşturulmasında fayda sağlar
              </li>
            </ul>
            <Typography paragraph={true} style={{ fontFamily: "ubuntuL" }}>
              Online sipariş almada ihtiyacınız olan tüm özellikler için{" "}
              <b>SepetTakip</b>’e hemen başvurun!
            </Typography>
            <Box sx={{ marginLeft: matches ? "50px" : "0px" }}>
              <img
                src={whyUsBottom}
                alt="why-us-bottom"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </div>
        </div>
        <Box
          sx={{
            position: matches ? "absolute" : "static",
            right: "0px",
            width: matches ? "40%" : "100%",
            top: "150px",
            paddingTop: matches ? "0px" : "25px",
            height: matches ? "auto" : "350px",
            display: matches ? "initial" : "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={whyUsBanner}
            alt="why-us-banner"
            style={{ maxWidth: "100%", height: matches ? "auto" : "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WhyUs;
