import { Box } from "@mui/material";
import Lottie from "react-lottie";
import loaderJson from "../../utils/assets/loading.json";

export default function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box
      sx={{
        height: "calc(100vh - 90px)",
        background: "#f3f3f5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
        }}
      >
        <Lottie options={defaultOptions} />
      </Box>
    </Box>
  );
}
