import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../components/error";
import paymentService from "../services/paymentService";
import TreedModal from "../components/modals/TreedModal";

export const PaymentContext = createContext();

const PaymentProvider = (props) => {
  let history = useHistory();
  const { alert, success } = useToast();
  const [iyzicoForm, setIyzicoForm] = useState(null);
  const [offerDetail, setOfferDetail] = useState(null);
  const [offerBillingList, setOfferBillingList] = useState(null);
  const [installmentInfo, setInstallmentInfo] = useState(null);
  const [toslaResult, setToslaResult] = useState(null);

  const [treedModal, setTreedModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [currentHtml, setCurrentHtml] = useState(null);

  const iyzicoFormFunc = (code) => {
    paymentService
      .iyzico_form(code)
      .then((response) => {
        setIyzicoForm(response.data);
        history.push(`/offers_v2/2/${code}/q`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const offerCodeFunc = (code) => {
    paymentService
      .offer_code(code)
      .then((response) => {
        setOfferDetail(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const offerBillingListFunc = (code) => {
    paymentService
      .offer_billing_list(code)
      .then((response) => {
        setOfferBillingList(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const offerBillingFunc = (data) => {
    paymentService
      .offer_billing(data)
      .then((response) => {
        iyzicoFormFunc(data.code);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const offerBillingv2Func = (data) => {
    paymentService
      .offer_billing(data)
      .then((response) => {
        history.push(`/offers/2/${data.code}`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const toslaInstallmentFunc = (data) => {
    paymentService
      .tosla_installment(data)
      .then((response) => {
        setInstallmentInfo(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const toslaInitFunc = (data) => {
    paymentService
      .tosla_init(data)
      .then((response) => {
        let sendObj = {
          paymentInfos: {
            ThreeDSessionId: response.data.ThreeDSessionId,
            CardHolderName: data.formData.name,
            CardNo: data.formData.number,
            ExpireDate: data.formData.expiry,
            Cvv: data.formData.cvc,
          },
          code: data.sendObj.code,
        };
        toslaPaymentFunc(sendObj);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const toslaPaymentFunc = (data) => {
    paymentService
      .tosla_payment(data)
      .then((response) => {
        history.push(`/offers/3/${data.code}`);
        setCurrentHtml(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const toslaResultFunc = (data) => {
    paymentService
      .tosla_result(data)
      .then((response) => {
        setToslaResult(response.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <PaymentContext.Provider
      value={{
        iyzicoFormFunc,
        iyzicoForm,
        offerCodeFunc,
        offerDetail,
        offerBillingFunc,
        offerBillingListFunc,
        offerBillingList,
        toslaInstallmentFunc,
        installmentInfo,
        setInstallmentInfo,
        toslaInitFunc,
        toslaPaymentFunc,
        toslaResultFunc,
        toslaResult,
        currentHtml,
        offerBillingv2Func,
      }}
    >
      {props.children}
      <TreedModal treedModal={treedModal} currentRow={currentRow} />
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
