import React from "react";
import SEO from "../../../../components/seo";
import contractPdf from "../../../../utils/stKvkk.pdf";

const Agreements = () => {
  return (
    <div style={{ width: "100%", height: "100%", paddingTop: "200px" }}>
      <SEO
        title="SepetTakip | Sözleşme"
        description="Sepettakip Sözleşmeler"
        name="Sepettakip"
        type="article"
      />

      <div style={{ maxWidth: "500px", margin: "auto" }}>
        <h4>Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni</h4>
        <p>
          <br /> Sepettakip Yazılım Teknolojileri A.Ş olarak; kişisel
          verilerinizin gizliliği ve güvenliği en önemli önceliklerimiz
          arasındadır.
          <br />
          <br /> Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun
          (“KVKK”) 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine
          getirmek amacıyla kişisel verileriniz hakkında sizleri bilgilendirmek
          isteriz. Kişisel veri, kimliğinizi belirli ya da belirlenebilir kılan
          her türlü bilgi anlamına gelmektedir. Sepet Takip tarafından
          tarafından işlenen kişisel verileriniz, bunların işlenme amaçları,
          aktarılabileceği alıcı grupları, toplanma yöntemi, hukuki sebebi ve
          söz konusu kişisel verilere ilişkin haklarınız aşağıda yer almaktadır.
          <br />
          <br />
          Madde 1. Sepet Takip tarafından hangi kişisel verileriniz
          işlenmektedir?
          <br />
          <br /> Sepet Takip platformunda yer alan üyelik koşullarını kabul
          ederek web sitesinden veya mobil cihazlara kurulan uygulamadan giriş
          yapan ve platformda yer alan ürünleri takip eden ve/veya satın alan
          kişiler “Üye” olarak tanımlanmaktadır. Sepet Takip platformunda üye
          olmanız halinde aşağıdaki kişisel verileriniz işlenebilmektedir.
          <br />
          <br /> 1.1 Adınız, soyadınız,
          <br />
          <br />
          1.2 İletişim Bilgileriniz: cep telefonu numaranız, fatura adresiniz,
          e-posta adresiniz,
          <br />
          <br />
          1.3 Fatura bilgileriniz, talep ve şikayet bilgileriniz
          <br />
          <br />
          1.4 İşlem Güvenliği Bilgileriniz: MAC adresi bilgisi, şifre ve parola
          bilgileri, çerez bilgileri,
          <br />
          <br />
          1.5 İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde
          ses kaydınız.
          <br />
          <br />
          Madde 2. Kişisel verilerinizin işlenmesinin amaçları ve toplama
          yöntemleri nelerdir?
          <br />
          <br />
          Kimlik, iletişim ve müşteri işlem bilgileriniz; sözleşmesel ilişkimiz
          kapsamında bizzat sizlerden, mobil uygulama ya da internet sitesinden
          elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve
          işlenmektedir.
          <br />
          <br />
          2.1 Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi.
          <br />
          <br />
          2.2 Finans ve muhasebe süreçlerinin yürütülmesi ve denetimi.
          <br />
          <br />
          2.3 Şirketin iş faaliyetlerinin yürütülmesi ve denetimi.
          <br />
          <br />
          2.4 Faturalandırma süreçlerinin yürütülmesi,
          <br />
          <br />
          2.5 İnternet sitesi/mobil uygulamalar üzerinden satın alım
          işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit
          edilmesi,
          <br />
          <br />
          2.6 Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam
          faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin
          tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş
          deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik
          faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.7 Müşteri memnuniyetini artırmak, platformdan alışveriş yapan
          müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde
          kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi
          ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.8 Stratejik analiz çalışmalarının yürütülmesi,
          <br />
          <br />
          2.9 İletişim faaliyetlerinin yürütülmesi,
          <br />
          <br />
          2.10 İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz ürünlerin
          sunulması,
          <br />
          <br />
          2.11 Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında
          Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen
          sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili
          iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,
          <br />
          <br />
          2.12 Açık rızanızın bulunması halinde, Sepet Takip nezdindeki
          gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi
          verilmesi,
          <br />
          <br />
          2.13 İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan
          müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına
          yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar
          aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket
          çalışmaları yapılması,
          <br />
          <br />
          2.14 Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin
          değerlendirilmesi,
          <br />
          <br />
          2.15 Ürünlere yönelik bilgi almanızın sağlanması,
          <br />
          <br />
          2.16 Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
          <br />
          <br />
          2.17 Bilgi güvenliği süreçlerinin yürütülmesi,
          <br />
          <br />
          2.18 Sepet Takip platformuna ilişkin olarak operasyonların
          güvenliğinin sağlanması,
          <br />
          <br />
          2.19 Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
          <br />
          <br />
          2.20 Hukuk işlerinin takibi ve yürütülmesi
          <br />
          <br />
          2.21 Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi
          <br />
          <br />
          İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat
          sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda
          otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
          <br />
          <br />
          2.22 Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
          <br />
          <br />
          2.23 Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
          <br />
          <br />
          2.24 İnternet sitesi/mobil uygulamalar üzerinden satın alım
          işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit
          edilmesi,
          <br />
          <br />
          2.25 Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam
          faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin
          tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş
          deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik
          faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.26 Müşteri memnuniyetini artırmak, platformdan alışveriş yapan
          müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde
          kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi
          ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.27 Bilgi güvenliği süreçlerinin yürütülmesi,
          <br />
          <br />
          2.28 Sepet Takip platformuna ilişkin olarak operasyonların
          güvenliğinin sağlanması,
          <br />
          <br />
          2.29 Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
          <br />
          <br />
          2.30 Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
          <br />
          <br />
          İşitsel kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz
          halinde aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
          <br />
          <br />
          2.31 Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
          <br />
          <br />
          2.31 İletişim faaliyetlerinin yürütülmesi,
          <br />
          <br />
          2.31 Kampanyaların yürütülmesi, tercihlerinize uygun ürünlerin
          tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş
          deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik
          faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.31 Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve
          iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
          <br />
          <br />
          2.31 İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz ürünlerin
          sunulması,
          <br />
          <br />
          2.31 Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında
          Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen
          sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili
          iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,
          <br />
          <br />
          2.31 Açık rızanızın bulunması halinde, Sepet Takip nezdindeki
          gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi
          verilmesi,
          <br />
          <br />
          2.31 İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan
          müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına
          yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar
          aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket
          çalışmaları yapılması,
          <br />
          <br />
          2.32 Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin
          değerlendirilmesi,
          <br />
          <br />
          2.32 Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
          <br />
          <br />
          2.32 Hukuk işlerinin takibi ve yürütülmesi,
          <br />
          <br />
          2.32 Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
          <br />
          <br />
          Madde 3. Kişisel verilerinizin işlenmesinin hukuki sebebi nedir?
          <br />
          <br />
          Sepet Takip “Sepet Takip tarafından hangi kişisel verileriniz
          işlenmektedir?” başlığında yer alan kişisel verilerinizi yukarıda yer
          alan amaçlarla ve KVKK’nın 5., 8. ve 9. maddelerinde belirtilen ve
          aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak
          işlenmektedir.
          <br />
          <br />
          3.1 6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 Sayılı
          Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve 6502 sayılı
          Tüketicinin Korunması Hakkında Kanun başta olmak üzere Şirketimizin
          tabi olduğu mevzuatta açıkça öngörülmüş olması hukuki sebebine
          dayanarak; şirket platformunda yer alan operasyonların güvenliğinin
          sağlanması, bilgi güvenliği süreçlerinin yürütülmesi, faaliyetlerin
          mevzuata uygun yürütülmesinin sağlanmasına yönelik faaliyetler başta
          olmak üzere mevzuattan kaynaklanan yükümlülüklerimizin yerine
          getirilmesi,
          <br />
          <br />
          3.2 Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
          kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki
          sebebine dayanarak; üyelik sözleşmesinin kurulması, mesafeli satış
          sözleşmesi gibi Tüketicinin Korunması Hakkında Kanun’un ilgili
          maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin
          kurulması faaliyetlerinin yürütülmesi, akdettiğimiz sözleşmelerin
          ifasına yönelik faaliyetlerin yürütülmesi, satın alım işlemlerinizin
          gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, şirketin iş
          faaliyetlerinin yürütülmesi ve denetimi, teslimat süreçlerinin
          takibinin yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve
          önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin
          yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,
          <br />
          <br />
          3.3 Şirketimizin hukuki yükümlülüğünün yerine getirebilmesi için
          zorunlu olması hukuki sebebine dayanarak; Mesafeli Sözleşmeler
          Yönetmeliği ve Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet
          Sağlayıcılar Hakkında Yönetmelik başta olmak üzere Şirketimizin tabii
          olduğu ikincil mevzuatta yer alan ve/veya yetkili merciiler tarafından
          yayınlanan karar, kılavuz ve rehberlerde belirtilen hukuki
          yükümlülüklerinin yerine getirilmesi, yetkili kişi, kurum ve
          kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun
          yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi,
          finans ve muhasebe işlerinin yürütülmesi,
          <br />
          <br />
          3.4 Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması hukuki sebebine dayanarak; hukuk ve dava işlerinin
          yürütülmesi,
          <br />
          <br />
          3.5 Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin
          meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki
          sebebine dayanarak; şirketimizin sunduğu ürün ve hizmetlerin
          geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi
          ve sizlere daha iyi bir alışveriş deneyiminin sağlanması,
          <br />
          <br />
          3.6 Açık rızanızın bulunması hukuki sebebine dayanarak; platformda yer
          alan ürünlere yönelik sorularınızın ve/veya yorumlarınızın ad-soyad
          bilginiz ile birlikte yayınlanması, çeşitli pazarlama ve reklam
          faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin
          tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş
          deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik
          faaliyetlerin yürütülmesi, Sepet Takip nezdindeki gelişmeler,
          fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, ticari
          elektronik iletiler gönderilmesi ve yurtdışına kişisel veri aktarımı
          yapılması.
          <br />
          <br />
          Madde 4. Sepet Takip kişisel verilerinizi kimlere hangi sebeplerle
          aktarıyor?
          <br />
          <br />
          Şirketimiz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği”
          ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve
          gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen
          göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş
          sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı
          paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz
          kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak
          durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve
          gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve
          güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve
          hizmet sağlayıcılarla çalışmak durumundayız. Kişisel verileriniz,
          pazaryeri operasyonun yürütülmesi,süreçlerinize yönelik yardım ve
          destek taleplerinizin yönetilmesi, üyelerimizin deneyimini ve
          memnuniyetin arttırmaya yönelik faaliyetlerin yürütülmesi, şirketimiz
          nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza
          bilgi verilmesi, şirketimizin sunduğu ürün ve hizmetlerin
          geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
          sizlere daha iyi bir alışveriş deneyiminin sağlanması ve
          memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, iş
          sürekliliğinin sağlanması, bilgi güvenliğinin sağlanması, kanuni
          yükümlülüklerin yerine getirilmesi amaçları başta olmak üzere ve işbu
          aydınlatma metninin ikinci maddesinde belirtilen amaçlarla sınırlı
          olarak gerekli olduğu ölçüde: Şirketimizin tabi olduğu mevzuatta
          açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi
          için zorunlu olması, sözleşmenin kurulması veya ifası için gerekli
          olması hukuki sebeplerine dayanarak;
          <br />
          <br />
          4.1 Satın alım süreçlerinin yürütülmesi amacıyla ürünün satıcısı ve
          hizmet sağlayıcıyla,
          <br />
          <br />
          4.2 Kargo, lojistik faaliyetlerin yürütülmesi ve teslimat süreçlerinin
          takibinin yapılması amacıyla sizin ve/veya ürünün adına teslim
          edileceği kişi bilgisi satın alınan ürünün satıcısıyla, kargo
          firmalarıyla ve özel kuryelerle,
          <br />
          <br />
          4.3 Alternatif teslimat modeliyle ürünlerin teslimatının yapılabilmesi
          amacıyla anlaşmalı olunan iş ortaklarımızla ve hizmet sağlayıcılarla,
          <br />
          <br />
          4.4 Faturalandırma süreçlerinin yürütülmesi amacıyla iş ortaklarımız,
          satın alınan ürünün satıcısı, danışmanlarımız ve hizmet
          sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
          <br />
          <br />
          4.5 Mal ve hizmet satış ve satış sonrası destek hizmetlerinin
          sunulması için çağrı merkezi hizmeti sunan iş ortakları ve diğer
          hizmet sağlayıcılarıyla,
          <br />
          <br />
          4.6 Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk analizi
          alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,
          <br />
          <br />
          4.7 Finansal ve muhasebe süreçlerinin yönetimi, risklerin tespiti,
          değerlendirilmesi, dolandırıcılıkların önlenmesi için ilgili iş
          ortaklarımız, danışmanlarımız ve hizmet sağlayıcılarımızla,
          bankalarla, mali müşavirlerimizle,
          <br />
          <br />
          4.8 Müşteriye e-faturasının elektronik olarak gönderilmesi için
          e-fatura iş ortağıyla; fiziki sözleşme veya fatura teslimatı
          yapılabilmesi amacıyla kargo ve kurye firmalarıyla, özel entegratör,
          bağımsız denetim, gümrük, mali müşavir/muhasebe hizmeti sunan iş
          ortaklarımızla,
          <br />
          <br />
          4.9 Vergisel yükümlülüklerin yerine getirilmesi için vergi
          daireleriyle, vergi denetimleri sırasında fatura ve tahsilat
          makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
          <br />
          <br />
          4.10 İletilerin yönetilmesi için ileti yönetim sistemi (İYS) kayıt
          bağlamında Ticaret Bakanlığı ve yetkilendirdiği şirketle ve ileti
          gönderimi için yetkili ticari ileti altyapı sağlayıcıyla,
          <br />
          <br />
          4.11 Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş
          ortaklarımızla ve hizmet sağlayıcılarımızla,
          <br />
          <br />
          4.12 Risk yönetimi ve finansal raporlama süreçlerinin yürütülmesi
          alanında hizmet sunan iş ortaklarımızla,
          <br />
          <br />
          Açık rızanızın bulunması hukuki sebebine dayanarak;
          <br />
          <br />
          4.13 Web sitesi kullanım tercihleri ve gezinti geçmişi, segmentasyon
          yapılması ve müşteri ile beğeni ve tercihleri doğrultusunda iletişime
          geçilmesi amacıyla ilgili hizmet sağlayıcı ve iş ortağıyla,
          <br />
          <br />
          4.14 Profilleme, analiz, sunulan hizmetlerin özelleştirilmesi,
          pazarlama ve reklam amaçlarıyla bu alanda hizmet sunan iş ortaklarıyla
          ve hizmet sağlayıcılarla,
          <br />
          <br />
          4.15 Pazarlama süreçlerinin yönetilmesi amacıyla ilgili iş
          ortaklarımızla,
          <br />
          <br />
          Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması, şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş
          olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu
          olması hukuki sebeplerine dayanarak;
          <br />
          <br />
          4.16 Hukuki yükümlülüklerin yerine getirilmesi kapsamında avukatlar,
          denetçiler, adli bilişim uzmanları, siber güvenlik danışmanları, vergi
          danışmanları ile danışmanlık ve hizmet aldığımız diğer üçüncü
          kişilerle ve iş ortaklarıyla,
          <br />
          <br />
          4.17 Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra
          müdürlükleri gibi yetkili kamu kurum ve kuruluşlarıyla,
          <br />
          <br />
          4.18 Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum
          veya kuruluşlarıyla, yurtiçi ve/veya yurtdışında bulunan
          iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı
          olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle
          paylaşılabilmektedir.
          <br />
          <br />
          Madde 5. SEPET TAKİP KİŞİSEL VERİLERİNİZİ NASIL KORUYOR?
          <br />
          <br />
          Sepet Takip ile paylaşılan kişisel veriler, Sepet Takip gözetimi ve
          kontrolü altındadır. Sepet Takip, yürürlükteki ilgili mevzuat
          hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması
          amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve
          uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir.
          Bu konudaki yükümlülüğümüzün bilincinde olarak;
          <br />
          <br />
          5.1 Veri gizliliğini konu alan uluslararası ve ulusal teknik
          standartlara uygun surette periyodik aralıklarda sızma testleri
          yaptırılmaktadır.
          <br />
          <br />
          5.2 Web sitesi, mobil site ve mobil uygulama aracılığıyla Sepet
          Takip’e ilettiğiniz kişisel verileriniz SSL (Secure Sockets Layer)
          teknolojisi kullanılarak korunmaktadır.
          <br />
          <br />
          5.3 Kişisel veri işleme faaliyetlerine ilişkin düzenli olarak risk
          analizleri yapılmakta ve risklerin azaltılması için aksiyonlar
          alınmaktadır.
          <br />
          <br />
          5.4 Kişisel verilere yetkisiz erişimleri engellemek için erişim ve
          yetki kontrolleri uygulanmaktadır
          <br />
          <br />
          5.5 Bu kapsamda veri işleme politikalarımızı her zaman
          güncellediğimizi bilginize sunarız.
          <br />
          <br />
          Madde 6. KİŞİSEL VERİLERİNİZİN KORUNMASINA YÖNELİK HAKLARINIZ
          <br />
          <br />
          Bu Aydınlatma Metni’nin “Hak ve Talepleriniz İçin İletişim” bölümünde
          yer alan yöntemlerle Şirketimize başvurarak,
          <br />
          <br />
          6.1 Kişisel verilerinizin işlenip işlenmediğini öğrenme,
          <br />
          <br />
          6.2 İşlenmişse buna ilişkin bilgi talep etme,
          <br />
          <br />
          6.3 Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme,
          <br />
          <br />
          6.4 Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,
          <br />
          <br />
          6.5 Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
          bunların düzeltilmesini isteme,
          <br />
          <br />
          6.6 KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin
          silinmesini veya yok edilmesini isteme,
          <br />
          <br />
          6.7 Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki
          haklarınız uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı
          üçüncü kişilere bildirilmesini isteme,
          <br />
          <br />
          6.8 İşlenen kişisel verilerinizin münhasıran otomatik sistemler ile
          analiz edilmesi sureti ile aleyhinize bir sonucun ortaya çıkmasına
          itiraz etme,
          <br />
          <br />
          6.9 Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi
          sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep
          etme haklarına sahipsiniz.
          <br />
          <br />
          Madde 7. HAK VE TALEPLERİNİZ İÇİN İLETİŞİM
          <br />
          <br />
          Kişisel verilerinizle ilgili sorularınızı ve taleplerinizi, Veri
          Sorumlusuna Başvuru Usul ve Esasları hakkında Tebliğ’de belirtilen
          şartlara uygun düzenlenmiş dilekçeyle aşağıdaki yöntemlerle
          iletebilirsiniz.
          <br />
          <br />
          BAŞVURU YÖNTEMI
          <br />
          Şahsen Yazılı Başvuru
          <br />
          <br />
          BAŞVURUNUN YAPILACAĞI ADRES
          <br />
          Kazlıçeşme Mah. 245. Sk No: 5 PK. 34010 Zeytinburnu, İstanbul/Türkiye
          <br />
          <br />
          Şirketimize daha önce bildirilen ve sistemde kayıtlı bulunan
          elektronik posta adresinizden info@sepettakip.com
          <br />
          <br />
          7.1 Şahsen Yazılı Başvuru” yoluna başvuracak İlgili Kişilerin
          kimliğini tevsik edici belgeleri ibraz etmesi zorunludur. Bu bağlamda
          Kazlıçeşme Mah. 245. Sk No: 5 PK. 34010 Zeytinburnu, İstanbul/Türkiye
          adresine yapacağınız şahsen yazılı başvurularda başvuru formuyla
          birlikte kimlik fotokopisinin yalnızca ön yüzünün (kan grubu ve din
          hanesi gözükmeyecek şekilde) ibraz edilmesini rica ederiz.
          <br />
          <br />
          7.2 Başvuru formunun tebligat zarfına veya e-postanın konu kısmına
          “Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi” yazılmasını
          rica ederiz. Kişisel veri sahipleri olarak, haklarınıza ilişkin
          taleplerinizi, Sepet Takip’e iletmeniz durumunda Sepet Takip
          talebinizi en geç 30 (otuz) gün içinde ücretsiz olarak
          sonuçlandıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat
          uyarınca bir maliyet gerektirmesi halinde Sepet Takip tarafından
          Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret
          talep edilebilecektir.
          <br />
          <br />
          Madde 8. AYDINLATMA METNİ HAKKINDA
          <br />
          <br />
          Sepet Takip işbu Kişisel Verilerin Korunması Hakkında Açıklama metnini
          yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her
          zaman güncelleme hakkını saklı tutar. Güncellenme Tarihi :11/08/2022
        </p>
      </div>
    </div>
  );
};

export default Agreements;
