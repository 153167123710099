import { Box, createTheme, useMediaQuery } from "@mui/material";
import sepetAppLogo from "../../utils/assets/sepetapp-logo.webp";
import trash from "../../utils/assets/trash.webp";

export default function SepetUyelik() {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box
      sx={{
        width: matches ? "50%" : "90%",
        marginLeft: "5%",
        marginRight: "5%",
        background: "#f3f3f3",
        height: "100vh",
        margin: "auto",
      }}
    >
      <Box sx={{ textAlign: "center", paddingTop: "50px" }}>
        <img src={sepetAppLogo} alt="sepet-app-logo" />
      </Box>

      <div style={{ padding: "0px 40px", fontFamily: "ubuntuM" }}>
        <h3>Hesabını Nasıl Silersin?</h3>
        <p>
          Hesabını silmek istediğine eminsen aşağıdaki adımları izleyebilirsin.
        </p>
        <p>1) Cep telefonundan SepetApp uygulamasını aç,</p>
        <p>2) Sağ üstte görünen profil bölümüne git,</p>
        <p>5)Yardıma tıkla,</p>
        <p>4)Açılan ekranda hesabımı sile tıkla,</p>
        <p>5) Son çıkan ekranda devam dedikten sonra hesabın silinecektir.</p>
        <p>6) Silme işlemi geri alınamaz.</p>
        <h3>Silinen ya da Saklanan Veri Türleri ve Ek Saklama Süreleri</h3>
        <p>
          Hesabınızı sildiğinizde size ait tüm veriler; adres, ödeme bilgileri,
          geçmiş siparişler, tanımlı indirimler ve SepetPay bakiyesi kalıcı
          olarak silinir.
        </p>
      </div>

      <Box sx={{ textAlign: "center" }}>
        <img src={trash} alt="trash" />
      </Box>
    </Box>
  );
}
