import React from "react";
import lottie from "lottie-web";
import failedPayedJson from "../../../utils/json/failedPayed.json";
import { useEffect } from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

let errCodeC = {
  10051: "Kart limiti yetersiz, yetersiz bakiye",
  10005: "İşlem onaylanmadı",
  10012: "Geçersiz işlem",
  10041: "Kayıp kart, karta el koyunuz",
  10043: "Çalıntı kart, karta el koyunuz",
  10054: "Vadesi dolmuş kart",
  10084: "CVC2 bilgisi hatalı",
  10057: "Kart sahibi bu işlemi yapamaz",
  10058: "Terminalin bu işlemi yapmaya yetkisi yok",
  10034: "Dolandırıcılık şüphesi",
  10093: "Kartınız e-ticaret işlemlerine kapalıdır. Bankanızı arayınız.",
  10201: "Kart, işleme izin vermedi",
  10204: "Ödeme işlemi esnasında genel bir hata oluştu",
  10206: "CVC uzunluğu geçersiz",
  10207: "Bankanızdan onay alınız",
  10208: "Üye işyeri kategori kodu hatalı",
  10209: "Bloke statülü kart",
  10210: "Hatalı CAVV bilgisi",
  10211: "Hatalı ECI bilgisi",
  10213: "BIN bulunamadı",
  10214: "İletişim veya sistem hatası",
  10215: "Geçersiz kart numarası",
  10216: "Bankası bulunamadı",
  10217: "Banka kartları sadece 3D Secure işleminde kullanılabilir",
  10219: "Bankaya gönderilen istek zaman aşımına uğradı",
  10222: "Terminal taksitli işleme kapalı",
  10223: "Gün sonu yapılmalı",
  10225: "Kısıtlı kart",
  10226: "İzin verilen PIN giriş sayısı aşılmış",
  10227: "Geçersiz PIN",
  10228: "Banka veya terminal işlem yapamıyor",
  10229: "Son kullanma tarihi geçersiz",
  10232: "Geçersiz tutar",
};

const FailedPayed = () => {
  let history = useHistory();
  let { id, code, errCode } = useParams();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: failedPayedJson,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <div
      style={{
        marginTop: matches ? 30 : 0,
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: matches ? "50%" : "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 200,
        }}
      >
        {" "}
        <div id="react-logo" style={{ width: "50%", height: "50%" }}></div>
        <div
          style={{
            fontFamily: "ubuntuB",
            fontSize: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Ödeme yapılamadı.
        </div>
        <div
          style={{
            fontFamily: "ubuntuB",
            fontSize: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "red",
          }}
        >
          {errCodeC[errCode] ? errCodeC[errCode] : "Bilinmeyen hata."}
        </div>
      </div>
    </div>
  );
};

export default FailedPayed;
