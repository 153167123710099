import { createTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 950,
      lg: 1350,
      xl: 1600,
    },
  },
});

export const useScreenBreakPoints = () => {
  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return {
    smScreen,
    mdScreen,
    lgScreen,
    xlScreen,
  };
};
