import { Box } from "@mui/material";
//webp
import teknolojiHizmet from "../../../../utils/assets/teknolojiHizmet.webp";
import card from "../../../../utils/assets/card.webp";
import entegrasyonCard from "../../../../utils/assets/entegrasyon_card.webp";
import masatakipCard from "../../../../utils/assets/masatakip_card.webp";
import sepetappCard from "../../../../utils/assets/sepetapp_card.webp";
import stoktakipCard from "../../../../utils/assets/stoktakip_card.webp";
import kuryetakipCard from "../../../../utils/assets/kuryetakip_card.webp";
import hizmetCard from "../../../../utils/assets/hizmet_card.webp";
import onlyPanelIcon from "../../../../utils/assets/onlyPanelIcon.webp";
import kuryeS from "../../../../utils/assets/kuryeS.webp";
import masa from "../../../../utils/assets/masa.webp";
import info from "../../../../utils/assets/info.webp";
import stok from "../../../../utils/assets/stok.webp";
import store from "../../../../utils/assets/store.webp";
import hizmet from "../../../../utils/assets/hizmet.webp";
import SadeceBirAppDeğil from "../../../../utils/assets/SadeceBirAppDeğil.webp";
import derece from "../../../../utils/assets/derece.webp";
import rightWay from "../../../../utils/assets/rightWay.webp";
import hizmetgroup from "../../../../utils/assets/hizmetgroup.webp";
import { useHistory } from "react-router-dom";
import { useScreenBreakPoints } from "../../../../theme";
function TechnologyAndService() {
  const { smScreen, mdScreen, lgScreen, xlScreen } = useScreenBreakPoints();
  let history = useHistory();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#030213",
      }}
    >
      {smScreen ? (
        <div
          style={{
            marginTop: 100,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              src={teknolojiHizmet}
              alt="Teknoloji ve Hizmetlerimiz"
              width={"60%"}
            />
          </div>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "65%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <img
                      alt="entegrasyonCard"
                      src={entegrasyonCard}
                      width={"25%"}
                      height={"25%"}
                    />
                    <img
                      alt="kuryetakipCard"
                      src={kuryetakipCard}
                      width={"25%"}
                      height={"25%"}
                    />
                    <img
                      alt="masatakipCard"
                      src={masatakipCard}
                      width={"25%"}
                      height={"25%"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: 30,
                      marginBottom: 100,
                    }}
                  >
                    <img
                      alt="stoktakipCard"
                      src={stoktakipCard}
                      width={"25%"}
                      height={"25%"}
                    />
                    <img
                      alt="sepetappCard"
                      src={sepetappCard}
                      width={"25%"}
                      height={"25%"}
                    />
                    <img
                      alt="hizmetCard"
                      src={hizmetCard}
                      width={"25%"}
                      height={"25%"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "35%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={SadeceBirAppDeğil}
                  alt="Sadece Bir App Değil"
                  width={"90%"}
                  height={70}
                  style={{ marginTop: 20 }}
                />
                <img
                  src={derece}
                  alt="360"
                  width={400}
                  height={240}
                  style={{ marginTop: 20 }}
                />
                <img src={hizmet} alt="Hizmet" width={220} height={70} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "90%",
                    height: 80,
                    backgroundImage:
                      "linear-gradient(98deg, #f53f62 -80%, #2a3c50 102%)",
                    borderRadius: 42,
                    marginTop: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/offer");
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineheight: "0.8",
                      fontSize: 45,
                      fontWeight: "bold",
                    }}
                  >
                    İncele
                  </span>
                  <img alt="right" src={rightWay} width={60} height={28} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "8%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              src={teknolojiHizmet}
              alt="Teknoloji ve Hizmetlerimiz"
              width={"90%"}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 12,
            }}
          >
            <img
              src={hizmetgroup}
              alt="Teknoloji ve Hizmetlerimiz"
              width={"60%"}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  marginRight: 25,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Tüm <br /> Entegrasyonlar <br />
                  Tek Panelde
                </span>
                <img
                  alt="entegrasyon"
                  src={onlyPanelIcon}
                  width={80}
                  height={80}
                  style={{ position: "absolute", top: "50%", left: "23%" }}
                />
              </div>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Markanıza Özel <br />
                  Native App
                </span>
                <img
                  alt="kurye"
                  src={store}
                  width={90}
                  height={95}
                  style={{ position: "absolute", top: "40%", left: "23%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  marginRight: 25,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Kurye Takip <br />
                  Özelliği
                </span>
                <img
                  alt="entegrasyon"
                  src={kuryeS}
                  width={75}
                  height={100}
                  style={{ position: "absolute", top: "40%", left: "25%" }}
                />
              </div>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Masa Siparişleri <br />
                  Yönetim Özelliği
                </span>
                <img
                  alt="entegrasyon"
                  src={masa}
                  width={95}
                  height={110}
                  style={{ position: "absolute", top: "36%", left: "20%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  marginRight: 25,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Envanter, <br /> Stok Yönetimi
                </span>
                <img
                  alt="entegrasyon"
                  src={stok}
                  width={90}
                  height={100}
                  style={{ position: "absolute", top: "36%", left: "23%" }}
                />
              </div>
              <div
                style={{
                  backgroundImage: `url(${card})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 200,
                  width: 150,
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 300,
                    position: "absolute",
                    top: 10,
                  }}
                >
                  Hizmetler
                </span>
                <img
                  alt="entegrasyon"
                  src={info}
                  width={110}
                  height={120}
                  style={{ position: "absolute", top: "25%", left: "15%" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}

export default TechnologyAndService;
