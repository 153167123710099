import axios from "axios";

// export const TOSLA_API_URL = process.env.TOSLA_BASE_URL;
export const TOSLA_API_URL = "https://entegrasyon.tosla.com/api/Payment/"; // PROD
// export const TOSLA_API_URL = "https://prepentegrasyon.tosla.com/api/Payment/"; // TEST

let instance = axios.create({
  baseURL: TOSLA_API_URL,
});

export const httpTosla = instance;
