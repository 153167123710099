import React, { useEffect } from "react";
import SEO from "../../components/seo";
//components
import AllOrderOnePanel from "./components/allOrderOnePanel";
import LearnEntegration from "./components/learnEntegration";
import WhySepetTakip from "./components/whySepetTakip";
import Entegration from "./components/Entegration";
import TechnologyAndService from "./components/technologyService";
import { Footer } from "./components/footer/Footer";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <SEO
        title="SepetTakip | Restoran Pos Entegrasyon Sistemi"
        description="SepetTakip | Restoran Pos Entegrasyon Sistemi"
        name="Sepettakip"
        type="article"
      />
      <AllOrderOnePanel />
      <LearnEntegration />
      <WhySepetTakip />
      <Entegration />
      <TechnologyAndService />
      <Footer />
    </div>
  );
};

export default Home;
