import React from "react";
import { Button, Card, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const TreedModal = ({ treedModal, currentRow }) => {
  const classes = useStyles();

  return (
    <Modal open={treedModal} className={classes.modal}>
      <Card
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          backgroundColor: "#fff",
          padding: 25,
          justifyContent: "space-between",
        }}
      >
        <iframe
          srcDoc={currentRow}
          title="HTML Content"
          style={{ width: "100%", height: "500px", border: "none" }}
        />
      </Card>
    </Modal>
  );
};

export default TreedModal;
