import React from "react";
//Mui
import { Box, useMediaQuery } from "@mui/material";
//webp
import whySepettakip from "../../../../utils/assets/whySepettakip.webp";
import whySepettakipS from "../../../../utils/assets/whySepettakipS.webp";
import allEnteg from "../../../../utils/assets/allEnteg.webp";
import rightWay from "../../../../utils/assets/rightWay.webp";
import { useHistory } from "react-router-dom";
import { useScreenBreakPoints } from "../../../../theme";

function WhySepetTakip() {
  const { smScreen, mdScreen, lgScreen, xlScreen } = useScreenBreakPoints();
  const matches = useMediaQuery("(min-width:650px)");

  let history = useHistory();
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: !smScreen ? "50vh" : "100vh",
        backgroundColor: "#030213",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: !smScreen ? "90%" : "80%", height: "100%" }}>
        <div style={{ marginLeft: !smScreen ? 0 : 100 }}>
          <img
            alt="Neden Sepet Takip"
            src={!smScreen ? whySepettakipS : whySepettakip}
            width={!smScreen ? "95%" : "65%"}
            height={!smScreen ? 30 : 100}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div>
              <p
                style={{
                  color: "#fff",
                  lineHeight: "1.57",
                  fontSize: xlScreen ? 24 : lgScreen ? 20 : smScreen ? 16 : 14,
                  marginTop: 12,
                }}
              >
                Dijitalleşen günümüzde online yemek siparişi sektörü giderek
                daha da büyümektedir ve birçok yemek siparişi uygulaması hizmet
                vermeye başlamıştır. Bu sektörün temel taşı olan restoranlar
                için tüm bu platformları ayrı ayrı yönetmek zorlaşmıştır.
              </p>
              <div
                style={{
                  color: "#fff",
                  lineHeight: "1.57",
                  fontSize: xlScreen ? 24 : lgScreen ? 20 : smScreen ? 16 : 14,
                  marginTop: 10,
                }}
              >
                Yemek siparişi uygulamalarını ayrı panellerden yönetmek işi
                karmaşıklaştırmakla beraber ciddi maddi zararlar da
                yaratabilmektedir.
                <br />
                <br />
                İşte bu yüzden, tüm sipariş yönetiminizi tek panelde ele almanız
                ve kârlı bir sipariş yönetimine geçmeniz için ihtiyacınız olan
                tüm özellikleriyle SepetTakip burada!
              </div>
            </div>
          </div>
          {matches && (
            <div
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt="Neden Sepet Takip"
                src={
                  "https://static.sepettakip.com/st-web-assets/shutterStock.webp"
                }
                width={"90%"}
                height={"90%"}
                style={{ minWidth: 220, maxHeight: !matches ? "60%" : "100%" }}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: !smScreen ? "90%" : "80%",
          alignItems: "center",
          flexDirection: "row",
          marginTop: smScreen ? 0 : 10,
        }}
      >
        <img
          src={allEnteg}
          alt="All"
          style={{
            marginTop: !smScreen ? 0 : 10,
            width: !smScreen ? "70%" : "70%",
            height: "100%",
          }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: xlScreen ? 220 : lgScreen ? 150 : smScreen ? 120 : 90,
            height: xlScreen ? 65 : lgScreen ? 50 : smScreen ? 40 : 32,
            backgroundImage:
              "linear-gradient(98deg, #f53f62 -80%, #2a3c50 102%)",
            borderRadius: 8,
            cursor: "pointer",
            marginLeft: !smScreen ? 10 : 20,
          }}
          onClick={() => {
            history.push("/offer");
          }}
        >
          <span
            style={{
              color: "#fff",
              lineheight: "0.8",
              fontSize: xlScreen ? 40 : lgScreen ? 28 : smScreen ? 24 : 20,
              fontWeight: "bold",
            }}
          >
            İncele
          </span>
          {!smScreen ? null : (
            <img
              alt="right"
              src={rightWay}
              width={xlScreen ? 48 : lgScreen ? 36 : smScreen ? 26 : 18}
              height={28}
            />
          )}
        </div>
      </div>
    </Box>
  );
}

export default WhySepetTakip;
