import { Box } from "@mui/material";
import { useScreenBreakPoints } from "../../theme";

import bgEllipse from "../../utils/assets/bg-ellipse.webp";
import bg from "../../utils/assets/why-sepettakip/bg.webp";
import sepetBg from "../../utils/assets/sepetapp-bg.webp";

export default function CallerId() {
  const { xxlScreen, xlScreen, lgScreen, mdScreen, smScreen } =
    useScreenBreakPoints();

  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f5",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={bgEllipse}
        alt="ellipse"
        style={{
          position: "absolute",
          left: "-40%",
          opacity: 0.7,
          zIndex: 1,
        }}
      />

      <h1
        style={{
          fontSize: xxlScreen
            ? "60px"
            : xlScreen
            ? "50px"
            : lgScreen
            ? "40px"
            : "24px",
          fontFamily: "ubuntuB",
          fontWeight: "bold",
          color: "#24233a",
          marginBottom: 10,
          textAlign: "center",
          maxWidth: 1100,
          margin: "70px auto 40px",
        }}
      >
        Gelen Aramaları Kolayca Kaydedin, Müşterinizin Bir Sonraki Siparişinde
        Zaman Kaybetmeyin!
      </h1>

      <Box
        sx={{
          textAlign: "center",
          margin: "auto",
          position: "relative",
          zIndex: 5,
        }}
      >
        <img
          src={
            "https://static.sepettakip.com/st-web-assets/caller-id/img-1.webp"
          }
        />
        <p
          style={{
            fontSize: xxlScreen ? "30px" : xlScreen ? "24px" : "18px",
            fontFamily: "ubuntuL",
            fontWeight: 300,
            color: "#24233a",
            maxWidth: 1000,
            margin: "auto",
          }}
        >
          Kayıtlı müşterilerinizden çok daha hızlı sipariş alın. Adres, iletişim
          ve sipariş bilgilerini kuryelerinize ve mutfağa tek tuşla yönlendirin.
        </p>
      </Box>

      <Box
        sx={{
          width: mdScreen ? "80%" : "90%",
          margin: "auto",
          display: "flex",
          flexDirection: mdScreen ? "row" : "column-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: mdScreen ? 10 : 2,
        }}
      >
        <p
          style={{
            fontSize: xxlScreen ? "30px" : xlScreen ? "24px" : "18px",
            fontFamily: "ubuntuL",
            fontWeight: 300,
            color: "#24233a",
          }}
        >
          Gelen aramayı kolayca kaydedin.
        </p>

        <img
          src={
            "https://static.sepettakip.com/st-web-assets/caller-id/img-2.webp"
          }
          alt="img-2"
        />
      </Box>

      <Box sx={{ position: "relative", marginTop: 5 }}>
        <img
          src={bg}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
          alt="img-bg"
        />
        <img
          alt="sepet-bg"
          src={sepetBg}
          style={{
            position: "absolute",
            top: 0,
            zIndex: 2,
            height: "auto",
            maxWidth: "100%",
          }}
        />
        <Box sx={{ position: "relative", zIndex: 7 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box>
              <img
                src={
                  "https://static.sepettakip.com/st-web-assets/caller-id/img-3.webp"
                }
                alt="image-5"
              />
            </Box>

            <Box sx={{ maxWidth: "600px", marginLeft: mdScreen ? 10 : 0 }}>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                }}
              >
                Siparişi hızlıca oluşturun.
              </p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: "relative", marginBottom: 12, zIndex: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: mdScreen ? "row" : "column-reverse",
              width: mdScreen ? "80%" : "90%",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 5,
            }}
          >
            <Box sx={{ maxWidth: "600px", marginRight: mdScreen ? 10 : 0 }}>
              <p
                style={{
                  fontSize: xxlScreen ? "30px" : xlScreen ? "25px" : "20px",
                  fontFamily: "ubuntuR",
                  fontWeight: 300,
                  color: "#24233a",
                  textAlign: mdScreen ? "left" : "center",
                }}
              >
                Siparişiniz oluşturuldu! Bir sonraki aramada artık müşteri
                bilgilerini otomatik göreceksiniz.
              </p>
            </Box>

            <Box>
              <img
                src={
                  "https://static.sepettakip.com/st-web-assets/caller-id/img-4.webp"
                }
                alt="img-6"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
