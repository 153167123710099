import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { ThemeProvider } from "@mui/styles";
import RegistrationProvider from "./contexts/registrationContext";
import PaymentProvider from "./contexts/paymentContext";

import { theme } from "./theme";
import Loader from "./components/loader";
//pages
import Home from "./pages/Home";

import Header from "./components/header";

import Agreements from "./pages/Home/components/agreements";
import Hızmet from "./pages/Home/components/agreements/hizmet";
import StGizlilik from "./pages/Home/components/agreements/stGizlilik";
import StKullanımKosulları from "./pages/Home/components/agreements/stKullanımKosulları";
import AboutUs from "./pages/AboutUs";
import WhyUs from "./pages/WhyUs";
import References from "./pages/References";
import Contact from "./pages/Contact";
import Campaigns from "./pages/Campaigns";
import Download from "./pages/Download";
import SepetUyelik from "./pages/sepetUyelik";
import SepetFast from "./pages/sepetFast";
import StokTakip from "./pages/stokTakip";
import CallerId from "./pages/callerId";
import PaymentResult from "./pages/Offers/components/paymentResult";
import OffersV2 from "./pages/OffersV2";

const WhySepetapp = React.lazy(() => import("./pages/whySepetApp"));
const CompletedRegistration = React.lazy(() =>
  import("./pages/Registration/completed")
);
const TakeLook = React.lazy(() => import("./pages/Home/components/takeLook"));
const KuryeApp = React.lazy(() => import("./pages/kuryeApp"));
const Offers = React.lazy(() => import("./pages/Offers"));
const Registration = React.lazy(() => import("./pages/Registration"));
const Offer = React.lazy(() => import("./pages/offer"));
const WhySepetTakip = React.lazy(() => import("./pages/whySepetTakip"));
const WhyMasaTakip = React.lazy(() => import("./pages/whyMasaTakip"));

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="title"
          content="SepetTakip | Restoran Pos Entegrasyon Sistemi"
          data-react-helmet="true"
        />
        <meta charset="utf-8" />
        <meta
          name="description"
          content="SepetTakip; Online siparişlerde Trendyol, Yemeksepeti, Getir ve Gofody'den gelen siparişleri, tek bir noktada buluşturmayı sağlayan bir entegrasyon sistemidir."
          data-react-helmet="true"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <RegistrationProvider>
          <PaymentProvider>
            {window.location.pathname.includes(
              "/sepetapp-uyelik-sonlandirma"
            ) || window.location.pathname.startsWith("/offers") ? null : (
              <Header />
            )}
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/why-us" component={WhyUs} />
                <Route path="/references" component={References} />
                <Route path="/Contact" component={Contact} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/take-a-look" component={TakeLook} />
                <Route
                  exact
                  path="/completed-registration"
                  component={CompletedRegistration}
                />
                <Route
                  exact
                  path="/offers/payment/result/:order_id"
                  component={PaymentResult}
                />
                <Route path="/offers/:id/:code" component={Offers} />
                <Route
                  path="/offers_v2/:id/:code/:errCode"
                  component={OffersV2}
                />
                <Route path="/campaigns/:type/:code" component={Campaigns} />
                <Route path="/why-sepettakip" component={WhySepetTakip} />
                <Route path="/kvkk" component={Agreements} />
                <Route path="/hizmet" component={Hızmet} />
                <Route path="/st-gizlilik" component={StGizlilik} />
                <Route path="/st-kk" component={StKullanımKosulları} />
                <Route path="/download" component={Download} />
                <Route path="/offer" component={Offer} />

                <Route path="/why-sepetapp" component={WhySepetapp} />

                <Route path="/why-masatakip" component={WhyMasaTakip} />
                <Route path="/sepetfast" component={SepetFast} />

                <Route path="/kuryeapp" component={KuryeApp} />

                <Route path="/stoktakip" component={StokTakip} />
                <Route path="/callerid" component={CallerId} />
                <Route
                  path="/sepetapp-uyelik-sonlandirma"
                  component={SepetUyelik}
                />
                <Redirect push to="/" />
              </Switch>
            </Suspense>
          </PaymentProvider>
        </RegistrationProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
