import React, { useState, useEffect } from "react";
import logo from "../../utils/assets/sepetTakipW.webp";

import { Box, useMediaQuery, createTheme, Button } from "@mui/material";
import { Navbar } from "../navbar/Navbar";
import { useHistory, useLocation, Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import sepettakip from "../../utils/assets/header-product-menu/sepet-takip.webp";
import sepetapp from "../../utils/assets/header-product-menu/sepet-app.webp";
import masatakip from "../../utils/assets/masatakip.webp";
import kuryeapp from "../../utils/assets/kıryeapp.webp";
import caller1 from "../../utils/assets/caller1.webp";
import stoknav from "../../utils/assets/stoknav.webp";
import sepetfast from "../../utils/assets/sepetfast.webp";
import { useScreenBreakPoints } from "../../theme";
const productsMenu = [
  {
    title: "SepetTakip",
    path: "/why-sepettakip",
    img: sepettakip,
    description:
      "Tüm Pazaryeri Entegrasyonları; Masa Sipariş Yönetimi, Kurye Takip ve Stok Takip Çözümleri Tek Yerde!",
  },
  {
    title: "SepetApp",
    path: "/why-sepetapp",
    img: sepetapp,
    description:
      "Kendi App’inizi kendi stilinizde tasarlayarak markanıza uygun bir app yaratmak artık çok kolay!",
  },
  {
    title: "MasaTakip",
    path: "/why-masatakip",
    img: masatakip,
    description:
      "Restoran İçi Sipariş Yönetimi Hiç Bu Kadar Kolay Olmamıştı...",
  },
  {
    title: "KuryeTakip",
    path: "/kuryeapp",
    img: kuryeapp,
    description:
      "Kendi Kuryesiyle Çalışan Restoran ve Marketlerin Kullanımı İçin Uygundur",
  },
  {
    title: "StokTakip",
    path: "/stoktakip",
    img: stoknav,
    description: "Stok Takibi Hiç Bu Kadar Detaylı ve Kolay Olmamıştı…",
  },
  {
    title: "Caller ID",
    path: "/callerid",
    img: caller1,
    description:
      "Gelen Aramaları Kolayca Kaydedin, Müşterinizin Bir Sonraki Siparişinde Zaman Kaybetmeyin!",
  },
  {
    title: "SepetFast",
    path: "/sepetfast",
    img: sepetfast,
    description: "Taşımacılık Hizmeti Veren Şirketler İçin Etkili Çözüm!",
  },
];
const Header = () => {
  let history = useHistory();
  const location = useLocation();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const { midScreen, lgScreen, mdScreen, smScreen } = useScreenBreakPoints();

  useEffect(() => {
    let path = history.location.pathname;
    setActiveLink(path);
  }, [history.location]);
  const handleClick = () => {
    setShowMenu(!showMenu);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999,
        height: matches ? 60 : 0,
        width: "100%",
        background: "#030213",
      }}
    >
      {matches ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:
              activeLink != "/offers/0" ? "space-between" : "flex-start",
            position: "static",
            height: "100%",
            width: "90%",
            marginLeft: "5%",
            marginRight: "5%",
            zIndex: 99,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              onClick={() => {
                history.push("/");
                setActiveLink("/");
                setShowMenu(false);
              }}
              alt="logo"
              src={logo}
              style={{
                width: 190,
                height: 28,
                cursor: "pointer",
              }}
            />
          </div>
          <div
            style={{
              color: "white",
              width: "60%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div
              onClick={() => {
                history.push("/about-us");
                setActiveLink("/about-us");
                setShowMenu(false);
              }}
              style={{
                fontWeight: 500,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>Hakkımızda</span>
              {activeLink == "/about-us" ? (
                <div
                  style={{
                    width: 20,
                    height: 2,
                    backgroundColor: "white",
                    marginTop: "2px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={handleClick}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Ürünler
              </span>
              {showMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
            {showMenu && (
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  right: "23%",
                  background: "#fff",
                  boxShadow: "20px 3px 50px 0px #000",
                  top: "75px",
                  borderRadius: "24px",
                  padding: "20px",
                  width: "50%",
                  minWidth: 900,
                }}
              >
                {productsMenu?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: "0px 20px 0px",
                      borderRight: "1px solid #24233a",
                      "&:last-child": {
                        borderRight: "none",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={item.path}
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          color: "#24233a",
                          fontFamily: "ubuntuR",
                          fontWeight: "bold",
                          marginBottom: "20px",
                          height: 50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.title}
                      </p>
                      <img
                        src={item.img}
                        alt={item.title}
                        style={{
                          marginBottom: 10,
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#24233a",
                          fontFamily: "ubuntuR",
                        }}
                      >
                        {item.description}
                      </p>
                    </Link>
                  </Box>
                ))}
              </Box>
            )}
            <div
              onClick={() => {
                history.push("/offer");
                setActiveLink("/offer");
                setShowMenu(false);
              }}
              style={{
                fontWeight: 500,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>Fiyatlarımız</span>
              {activeLink == "/offer" ? (
                <div
                  style={{
                    width: 20,
                    height: 2,
                    backgroundColor: "white",
                    marginTop: "2px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
            <div
              onClick={() => {
                history.push("/references");
                setActiveLink("/references");
                setShowMenu(false);
              }}
              style={{
                fontWeight: 500,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>Referanslarımız</span>
              {activeLink == "/references" ? (
                <div
                  style={{
                    width: 20,
                    height: 2,
                    backgroundColor: "white",
                    marginTop: "2px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
            <div
              onClick={() => {
                history.push("/contact");
                setActiveLink("/contact");
                setShowMenu(false);
              }}
              style={{
                fontWeight: 500,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>İletişim</span>
              {activeLink == "/contact" ? (
                <div
                  style={{
                    width: 20,
                    height: 2,
                    backgroundColor: "white",
                    marginTop: "2px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </div>

          {activeLink !== "/offers/0" &&
            (activeLink === "/" ||
              activeLink === "/about-us" ||
              activeLink === "/why-us" ||
              activeLink === "/features" ||
              activeLink === "/offer" ||
              activeLink === "/references" ||
              activeLink === "/why-sepettakip" ||
              activeLink === "/why-sepetapp" ||
              activeLink === "/callerid" ||
              activeLink === "/stoktakip" ||
              activeLink === "/kuryeapp" ||
              activeLink === "/registration" ||
              activeLink === "/sepetfast" ||
              activeLink === "/why-masatakip" ||
              activeLink === "/contact" ||
              activeLink === "/download" ||
              activeLink === "/take-a-look") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {activeLink !== "/registration" ? (
                    <Button
                      onClick={() => {
                        history.push("/registration");
                        setActiveLink("/registration");
                        setShowMenu(false);
                      }}
                      style={{
                        backgroundColor: "#030213",
                        textTransform: "capitalize",
                        borderRadius: 13,
                        height: 28,
                        borderColor: "white",
                        border: "solid",
                        fontSize: 16,
                        minWidth: "165px",
                      }}
                      size="small"
                      variant="contained"
                    >
                      Sizi Arayalım
                    </Button>
                  ) : (
                    <div style={{ minWidth: 165 }}></div>
                  )}
                </div>
              </div>
            )}
        </Box>
      ) : (
        <Navbar />
      )}
    </Box>
  );
};

export default Header;
