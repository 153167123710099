import reference1 from "./reference-1.webp";
import reference2 from "./reference-2.webp";
import reference3 from "./reference-3.webp";
import reference4 from "./reference-4.webp";
import reference5 from "./reference-5.webp";
import reference6 from "./reference-6.webp";
import reference7 from "./reference-7.webp";
import reference8 from "./reference-8.webp";
import reference9 from "./reference-9.webp";
import reference10 from "./reference-10.webp";
import reference11 from "./reference-11.webp";
import reference12 from "./reference-12.webp";
import reference13 from "./reference-13.webp";
import reference14 from "./reference-14.webp";
import reference15 from "./reference-15.webp";
import reference16 from "./reference-16.webp";
import reference17 from "./reference-17.webp";
import reference18 from "./reference-18.webp";
import reference19 from "./reference-19.webp";
import reference20 from "./reference-20.webp";
import reference21 from "./reference-21.webp";
import reference22 from "./reference-22.webp";
import reference23 from "./reference-23.webp";
import reference24 from "./reference-24.webp";
import reference25 from "./reference-25.webp";
import reference26 from "./reference-26.webp";
import reference27 from "./reference-27.webp";
import reference28 from "./reference-28.webp";
import reference29 from "./reference-29.webp";
import reference30 from "./reference-30.webp";
import reference31 from "./reference-31.webp";
import reference32 from "./reference-32.webp";
import reference33 from "./reference-33.webp";
import reference34 from "./reference-34.webp";
import reference35 from "./reference-35.webp";

export const referenceImages = [];
referenceImages.push(
  reference1,
  reference2,
  reference3,
  reference4,
  reference5,
  reference6,
  reference7,
  reference8,
  reference9,
  reference10,
  reference11,
  reference12,
  reference13,
  reference14,
  reference15,
  reference16,
  reference17,
  reference18,
  reference19,
  reference20,
  reference21,
  reference22,
  reference23,
  reference24,
  reference25,
  reference26,
  reference27,
  reference28,
  reference29,
  reference30,
  reference31,
  reference32,
  reference33,
  reference34,
  reference35
);
