import React, { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../../contexts/paymentContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SuccessPayedV2 from "./successPayedv2";
import FailedPayedV2 from "./failedPayedv2";

const PaymentResult = () => {
  let { order_id } = useParams();
  const { toslaResultFunc, toslaResult } = useContext(PaymentContext);
  const [toslaResultObj, setToslaResultObj] = useState(null);

  useEffect(() => {
    toslaResultFunc({ order_id });
  }, []);

  useEffect(() => {
    if (toslaResult !== null) {
      setToslaResultObj(toslaResult);
    }
  }, [toslaResult]);

  return (
    <div>
      {toslaResultObj &&
      toslaResultObj !== null &&
      toslaResultObj?.payment_successful ? (
        <SuccessPayedV2 />
      ) : (
        <FailedPayedV2 errMessage={toslaResultObj?.response_message} />
      )}
    </div>
  );
};

export default PaymentResult;
