import { http } from "./axios";
import { httpTosla } from "./axiosTosla";

function iyzico_form(code) {
  return http.post(`/restaurant/iyzico_form`, {
    code: code,
  });
}

function offer_code(code) {
  return http.get(`/restaurant/offer?code=${code}`);
}

function offer_billing_list(code) {
  return http.get(`/restaurant/offer/billing?code=${code}`);
}

function offer_billing(data) {
  return http.post(`/restaurant/offer/billing`, data);
}

function tosla_installment(data) {
  return http.post(`/restaurant/tosla/installment`, data);
}

function tosla_init(data) {
  return http.post(`/restaurant/tosla/init`, data.sendObj);
}

function tosla_payment(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("ThreeDSessionId", data.paymentInfos.ThreeDSessionId);
  bodyFormData.append("CardHolderName", data.paymentInfos.CardHolderName);
  bodyFormData.append("CardNo", data.paymentInfos.CardNo);
  bodyFormData.append("ExpireDate", data.paymentInfos.ExpireDate);
  bodyFormData.append("Cvv", data.paymentInfos.Cvv);
  return httpTosla.post(`/ProcessCardForm`, bodyFormData, {
    "Content-Type": "multipart/form-data",
  });
}

function tosla_result(data) {
  return http.get(`/restaurant/tosla/result?order_id=${data.order_id}`);
}

export default {
  iyzico_form,
  offer_code,
  offer_billing_list,
  offer_billing,
  tosla_installment,
  tosla_init,
  tosla_payment,
  tosla_result,
};
