import React from "react";
import { useHistory } from "react-router-dom";
//Mui
import { Box, Button, useMediaQuery } from "@mui/material";
//webp
import gözatIcon from "../../../../utils/assets/gözat_icon.webp";

import ReactPlayer from "react-player/lazy";
import { makeStyles } from "@mui/styles";
import { useScreenBreakPoints } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
  video: {
    "& video": {
      clipPath: "inset(3px 2px)",
    },
  },
}));

function LearnEntegration() {
  let history = useHistory();
  const classes = useStyles();
  const { smScreen, mdScreen, lgScreen, xlScreen } = useScreenBreakPoints();
  const matches = useMediaQuery("(min-width:500px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: matches ? "100vh" : "60vh",
        backgroundColor: "#030213",
        display: "flex",
      }}
    >
      {matches ? (
        <div
          style={{
            width: "82%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <ReactPlayer
            url={
              "https://static.sepettakip.com/st-web-assets/EntegrasyonuTaniyin.mp4"
            }
            loop={true}
            playing={true}
            muted
            width={"100%"}
            height={"100%"}
            className={classes.video}
            playsinline
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: smScreen ? 70 : 25,
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              color: "#fff",
              lineHeight: "1.2",
              fontSize: 30,
              textAlign: "center",
            }}
          >
            Sistemi Tanıyın!
          </span>

          <ReactPlayer
            url={
              "https://static.sepettakip.com/st-web-assets/EntegrasyonuTaniyin.mp4"
            }
            loop={true}
            playing={true}
            muted
            width={"100%"}
            height={"100%"}
            className={classes.video}
            playsinline
          ></ReactPlayer>
          <Button
            size="small"
            variant="contained"
            startIcon={
              smScreen ? (
                <img
                  src={gözatIcon}
                  style={{
                    width: "90%",
                    height: "90%",
                    maxHeight: 45,
                    maxWidth: 45,
                  }}
                ></img>
              ) : (
                ""
              )
            }
            onClick={() => {
              history.push("/take-a-look");
            }}
            style={{
              background: `linear-gradient(to right,  ${"#4E3B53"} 0%,${"#CB3E5F"} 100%)`,
              display: "flex",
              justifyContent: "space-around",
              cursor: "pointer",
              maxWidth: 200,
              maxHeight: 50,
              textTransform: "capitalize",
              fontSize: lgScreen ? 25 : smScreen ? 22 : 12,
              fontFamily: "ubuntuM",
            }}
          >
            Göz At
          </Button>
        </div>
      )}

      {matches && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "15%",
            justifyContent: "flex-end",
            height: "65%",
          }}
        >
          <span
            style={{
              color: "#fff",
              lineHeight: "1.5",
              fontSize: xlScreen ? 50 : lgScreen ? 35 : smScreen ? 25 : 15,
            }}
          >
            Sistemi <br /> Tanıyın!
          </span>
          <Button
            size="small"
            variant="contained"
            startIcon={
              smScreen ? (
                <img
                  src={gözatIcon}
                  style={{
                    width: "90%",
                    height: "90%",
                    maxHeight: 45,
                    maxWidth: 45,
                  }}
                ></img>
              ) : (
                ""
              )
            }
            onClick={() => {
              history.push("/take-a-look");
            }}
            style={{
              background: `linear-gradient(to right,  ${"#4E3B53"} 0%,${"#CB3E5F"} 100%)`,
              display: "flex",
              justifyContent: "space-around",
              cursor: "pointer",
              maxWidth: 200,
              maxHeight: 50,
              textTransform: "capitalize",
              fontSize: lgScreen ? 25 : smScreen ? 22 : 12,
              fontFamily: "ubuntuM",
            }}
          >
            Göz At
          </Button>
        </div>
      )}
    </Box>
  );
}

export default LearnEntegration;
